import Button from 'antd/es/button';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import BusinessImg from '@assets/undraw_engineering_team.svg';
import ReceiptImg from '@assets/undraw_receipt.svg';
import ContentContainer from '@components/ContentContainer';
import { Hero } from '@components/Hero';
import InviteTeamMemberButton from '@components/InviteTeamMemberButton';
import OnboardingChecklist from '@components/OnboardingChecklist';
import { useFetchUserQuery } from '@queries/users';
import { useSessionUserId } from '@utils/auth';
import { useTeamIsActive } from '@utils/billing';
import { useTeamId } from '@utils/router';

const Home = () => {
  const teamId = useTeamId();
  const teamIsActive = useTeamIsActive();

  const currentTime = new Date().getHours();
  const greeting =
    (currentTime < 12 && 'Good morning') ||
    (currentTime < 18 && 'Good afternoon') ||
    'Good evening';
  const sessionUserId = useSessionUserId();
  const fetchUserQuery = useFetchUserQuery(sessionUserId);

  const firstName = fetchUserQuery.data?.name?.split(' ')[0];

  const hero = useMemo(() => {
    if (teamIsActive) {
      return (
        <Hero
          id="homeBanner-basic"
          color={{
            light:
              'linear-gradient(90deg, rgba(98, 92, 221, 0.3) 0%, rgba(98, 92, 221, 0.2) 50%, rgba(98, 92, 221, 0.35) 100%)',
            dark: 'linear-gradient(90deg, rgba(52,49,101,0.6) 0%, rgba(52,49,101,0.9) 50%, rgba(52,49,101,0.45) 100%)',
          }}
          imgSrc={BusinessImg}
          title="Get started with your team today"
          extra={
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              <InviteTeamMemberButton teamId={teamId} />
              <Link to={`/team/${teamId}/manage/users`}>
                <Button>View members</Button>
              </Link>
            </div>
          }
        />
      );
    }
    return (
      <Hero
        id="homeBanner-billing"
        color={{
          light:
            'linear-gradient(90deg, rgba(98, 92, 221, 0.3) 0%, rgba(98, 92, 221, 0.2) 50%, rgba(98, 92, 221, 0.35) 100%)',
          dark: 'linear-gradient(90deg, rgba(52,49,101,0.6) 0%, rgba(52,49,101,0.9) 50%, rgba(52,49,101,0.45) 100%)',
        }}
        imgSrc={ReceiptImg}
        title="Setup billing for your team to unlock all features"
        extra={
          <div
            style={{ display: 'flex', gap: '0.5rem', flexDirection: 'column' }}
          >
            Historical data will still be available, but other features are
            locked until billing is set up.
            <Link to={`/team/${teamId}/billing`}>
              <Button
                type="primary"
                style={{
                  boxShadow: '0px 0px 0px rgba(98, 92, 221, 0.2)',
                }}
              >
                Setup Billing
              </Button>
            </Link>
          </div>
        }
      />
    );
  }, [teamId, teamIsActive]);

  if (fetchUserQuery.isLoading) {
    return <ContentContainer.Loading />;
  }

  if (fetchUserQuery.isError) {
    return <ContentContainer.Error />;
  }

  return (
    <ContentContainer>
      <ContentContainer.Header
        title={`${greeting}, ${firstName}`}
        breadcrumbs={[{ label: 'Home' }]}
      />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        {hero}
        <OnboardingChecklist />
      </div>
    </ContentContainer>
  );
};

export default Home;
