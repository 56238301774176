import Spin from 'antd/es/spin';
import Typography from 'antd/es/typography';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { useSessionUserId } from '@utils/auth';
import { logger } from '@utils/logger';

const MAX_WAIT_TIME = 5000;

const TeamsRoot = () => {
  const sessionUserId = useSessionUserId();
  const [shouldRedirectToLogin, setShouldRedirectToLogin] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (sessionUserId) return;
      logger.error(
        {
          sessionUserId,
        },
        'loading is taking longer than expected',
      );
      setShouldRedirectToLogin(true);
    }, MAX_WAIT_TIME);
    return () => {
      clearTimeout(timer);
    };
  });

  if (!sessionUserId) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        {shouldRedirectToLogin ? (
          <Typography.Text type="secondary">
            Loading is taking longer than expected, please try refreshing the
            page or return to <a href="/auth/login">login</a>.
          </Typography.Text>
        ) : (
          <div>
            <Typography.Text>Loading...</Typography.Text>
            <Spin
              style={{
                marginLeft: '1rem',
              }}
            />
          </div>
        )}
      </div>
    );
  }

  return <Outlet />;
};

export default TeamsRoot;
