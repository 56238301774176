import EditOutlined from '@ant-design/icons/EditOutlined';
import LinkOutlined from '@ant-design/icons/LinkOutlined';
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import Button from 'antd/es/button';
import Descriptions from 'antd/es/descriptions';
import Dropdown from 'antd/es/dropdown';
import Modal from 'antd/es/modal';
import { useSearchParams } from 'react-router-dom';

import ActiveTeamAlert from '@components/ActiveTeamAlert';
import ContentContainer from '@components/ContentContainer';
import LinkDocumentsToProject from '@components/LinkDocumentsToProject';
import SectionContainer from '@components/SectionContainer';
import CreateDocumentForm from '@components/forms/CreateDocumentForm';
import EditProjectForm from '@components/forms/EditProjectForm';
import DocumentsTable from '@components/tables/DocumentsTable';
import { useProjectQuery } from '@queries/projects';
import { useTeamIsActive } from '@utils/billing';
import { renderDate } from '@utils/dates';
import { useProjectId, useTeamId } from '@utils/router';

type ActiveModal = 'edit-details' | 'link-documents' | 'upload-documents';

const ProjectDetailsPage = () => {
  const teamId = useTeamId();
  const projectId = useProjectId();
  const [searchParams, setSearchParams] = useSearchParams();
  const teamIsActive = useTeamIsActive();
  const activeModal = searchParams.get('activeModal') as ActiveModal | null;

  const projectQuery = useProjectQuery(projectId);
  const project = projectQuery.data;

  if (projectQuery.isLoading || !teamId || !projectId) {
    return <ContentContainer.Loading />;
  }

  if (projectQuery.isError) {
    return <ContentContainer.Error />;
  }

  if (!project) {
    return <ContentContainer.NotFound />;
  }

  const handleCloseModal = () => {
    setSearchParams((prev) => {
      prev.delete('activeModal');
      return prev;
    });
  };

  const handleOpenModal = (modal: ActiveModal) => {
    setSearchParams((prev) => {
      prev.set('activeModal', modal);
      return prev;
    });
  };

  return (
    <ContentContainer>
      <Modal
        open={activeModal === 'edit-details'}
        title="Edit Project Details"
        onCancel={handleCloseModal}
        footer={null}
        closable
        onClose={handleCloseModal}
        destroyOnClose
      >
        <EditProjectForm project={project} onSuccess={handleCloseModal} />
      </Modal>
      <Modal
        open={activeModal === 'link-documents'}
        title="Link Documents"
        onCancel={handleCloseModal}
        footer={null}
        closable
        onClose={handleCloseModal}
        destroyOnClose
        width={1000}
      >
        <LinkDocumentsToProject
          project={project}
          onSuccess={handleCloseModal}
        />
      </Modal>
      <Modal
        open={activeModal === 'upload-documents'}
        title="Upload Documents"
        onCancel={handleCloseModal}
        footer={null}
        closable
        onClose={handleCloseModal}
        destroyOnClose
      >
        <CreateDocumentForm
          teamId={teamId}
          projectId={projectId}
          onFinish={handleCloseModal}
          initialLinks={[
            { id: projectId, type: 'project' },
            { id: teamId, type: 'team' },
          ]}
        />
      </Modal>
      <ContentContainer.Header
        title={projectQuery.data?.name}
        breadcrumbs={[
          {
            label: 'Home',
            to: `/team/${teamId}`,
          },
          {
            label: 'Projects',
            to: `/team/${teamId}/projects`,
          },
          {
            label: project.name,
          },
        ]}
        subtitle={
          !teamIsActive ? <ActiveTeamAlert teamId={teamId} /> : undefined
        }
      />
      <SectionContainer
        title="Details"
        cta={
          <Button
            type="default"
            size="small"
            onClick={() => handleOpenModal('edit-details')}
            icon={<EditOutlined />}
          >
            Edit Details
          </Button>
        }
      >
        <Descriptions column={3}>
          <Descriptions.Item label="Name">{project.name}</Descriptions.Item>
          <Descriptions.Item label="Created At">
            {renderDate(project.createdAt)}
          </Descriptions.Item>
          <Descriptions.Item label="Updated At">
            {renderDate(project.updatedAt)}
          </Descriptions.Item>
          <Descriptions.Item label="Description">
            {project.description ?? '-'}
          </Descriptions.Item>
        </Descriptions>
      </SectionContainer>
      <SectionContainer
        title="Documents"
        cta={
          <div>
            <Dropdown.Button
              size="small"
              menu={{
                items: [
                  {
                    key: 'link-documents',
                    label: 'Link Existing Documents',
                    icon: <LinkOutlined />,
                    onClick: () => handleOpenModal('link-documents'),
                  },
                  {
                    key: 'upload-documents',
                    label: 'Upload Documents',
                    icon: <UploadOutlined />,
                    onClick: () => handleOpenModal('upload-documents'),
                  },
                ],
              }}
              trigger={['click']}
              onClick={() => handleOpenModal('upload-documents')}
            >
              <UploadOutlined />
              Upload Documents
            </Dropdown.Button>
          </div>
        }
      >
        <DocumentsTable
          teamIds={[teamId]}
          projectIds={[projectId]}
          size="small"
          showHeader={false}
        />
      </SectionContainer>
    </ContentContainer>
  );
};

export default ProjectDetailsPage;
