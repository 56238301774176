import App from 'antd/es/app';
import Select from 'antd/es/select';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import CenterSpin from '@components/CenterSpin';
import Conversation from '@components/Conversation';
import { useFetchConversations } from '@queries/conversations';
import { API_BASE_URL } from '@queries/index';
import { logger } from '@utils/logger';

const EmbedConversationPage = () => {
  const { message } = App.useApp();
  const [searchParams] = useSearchParams();
  const [sessionToken, setSessionToken] = useState<string | null>(
    window.localStorage.getItem('embedSessionToken'),
  );
  const [selectedConversationId, setSelectedConversationId] = useState<
    string | null
  >(null);

  // Mandatory for setting up the session
  const embedAccessToken = searchParams.get('token');
  const userFullName = searchParams.get('userFullName') ?? undefined;
  const projectId = searchParams.get('projectId') ?? undefined;

  // Exchange the embed access token and glideUserId
  useEffect(() => {
    if (embedAccessToken && !sessionToken) {
      axios
        .post(`${API_BASE_URL}/embed/initialize`, {
          token: embedAccessToken,
        })
        .then(({ data }) => {
          setSessionToken(data.token);
          window.localStorage.setItem('embedSessionToken', data.token);
          void message.success('Embed session initialized');
        })
        .catch((error) => {
          logger.error('Failed to initialize embed session', error);
          void message.error('Failed to initialize embed session');
        });
    }
  }, [embedAccessToken, message, sessionToken]);

  // List historical conversations
  const conversationsQuery = useFetchConversations({
    pageSize: 10,
    orderBy: 'createdAt',
    orderDirection: 'desc',
  });
  const conversations = conversationsQuery.data ?? [];
  const selectedConversation = conversations.find(
    (conversation) => conversation.id === selectedConversationId,
  );

  // After initial load, select the most recent conversation if available
  useEffect(() => {
    if (conversationsQuery.data && conversationsQuery.data.length > 0) {
      setSelectedConversationId(conversationsQuery.data[0].id);
    }
  }, [conversationsQuery.data]);

  if (conversationsQuery.isLoading) {
    return <CenterSpin />;
  }

  return (
    <>
      <Select
        options={conversations.map((conversation) => ({
          label: conversation.name,
          value: conversation.id,
        }))}
        onChange={(value) => setSelectedConversationId(value)}
        value={selectedConversationId}
        style={{ width: '100%' }}
        placeholder="Select a conversation..."
      />
      {selectedConversation && (
        <Conversation
          conversationId={selectedConversation.id}
          teamId={selectedConversation.teamId}
          projectId={projectId}
          userFullName={userFullName}
        />
      )}
    </>
  );
};

export default EmbedConversationPage;
