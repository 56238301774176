import { useQuery } from '@tanstack/react-query';
import type { RcFile } from 'antd/es/upload/interface';

import {
  type CreateDocumentRequest,
  type DocumentDetail,
  DocumentDetailSchema,
  type ListDocumentExtractionsQuery,
  type ListDocumentExtractionsResponse,
  ListDocumentExtractionsResponseSchema,
  type ListDocumentProcessingEventsQuery,
  type ListDocumentProcessingEventsResponse,
  ListDocumentProcessingEventsResponseSchema,
  type ListDocumentsQuery,
  type ListDocumentsResponse,
  ListDocumentsResponseSchema,
} from '@mai/types';

import { apiClient } from '.';

import { logger } from '@utils/logger';

export const useFetchDocumentsQuery = (
  args: ListDocumentsQuery,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey: ['documents', args],
    queryFn: async (): Promise<{
      count?: number;
      data: ListDocumentsResponse;
    }> => {
      const { data, headers } = await apiClient.get<ListDocumentsResponse>(
        '/documents',
        {
          params: args,
        },
      );
      const validatedData = ListDocumentsResponseSchema.safeParse(data);
      const count = parseInt(headers['x-total-count'] ?? '0', 10);
      if (validatedData.success) {
        return {
          count,
          data: validatedData.data,
        };
      } else {
        logger.error({
          message: 'Failed to fetch documents',
          error: validatedData.error,
        });
        return {
          data,
          count: 0,
        };
      }
    },
    enabled,
  });
};

export const useFetchDocumentQuery = (documentId?: string) => {
  return useQuery({
    queryKey: ['document', documentId],
    queryFn: async () => {
      const { data } = await apiClient.get<DocumentDetail>(
        `/documents/${documentId}`,
      );

      const validatedData = DocumentDetailSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      } else {
        logger.error({
          message: 'Failed to fetch document',
          error: validatedData.error,
        });
        return data;
      }
    },
  });
};

export const useFetchDocumentExtractionsQuery = (
  args: ListDocumentExtractionsQuery,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey: ['document-extractions', args],
    queryFn: async () => {
      const { data } = await apiClient.get<ListDocumentExtractionsResponse>(
        '/document-extractions',
        {
          params: args,
        },
      );
      const validatedData =
        ListDocumentExtractionsResponseSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      } else {
        logger.error({
          message: 'Failed to fetch document extractions',
          error: validatedData.error,
        });
        return data;
      }
    },
    enabled,
  });
};

/**
 * Given a title, description, and files, create a document on the backend and yield the document ID.
 */
export async function createDocument({
  title,
  description,
  file,
  links,
}: Pick<CreateDocumentRequest, 'title' | 'description' | 'links'> & {
  file: RcFile;
}): Promise<DocumentDetail> {
  if (!file) {
    throw new Error('No file provided.');
  }
  if (!title) {
    throw new Error('No title provided.');
  }
  if (!file) {
    throw new Error('No file provided.');
  }

  const fileType = file.type ?? 'unknown';
  const fileName = file.name;

  // Create the document
  let createDocumentResponse;
  try {
    const { data } = await apiClient.post<DocumentDetail>('/documents', {
      title,
      description: description || '',
      fileType,
      fileName,
      links,
    } satisfies CreateDocumentRequest);
    createDocumentResponse = data;
  } catch (e) {
    throw new Error('Failed to create document.');
  }

  try {
    // Upload the file to the upload URL
    const url = createDocumentResponse.uploadUrl;
    await fetch(url, {
      method: 'PUT',
      body: file,
    });
  } catch (e) {
    logger.warn({ message: 'Failed to upload document.', error: e });

    // Delete the document if the upload
    try {
      await apiClient.delete(`/documents/${createDocumentResponse.id}`);
    } catch (e) {
      logger.error({
        message: 'Failed to delete document after failed upload.',
        error: e,
      });
    }

    throw new Error('Failed to upload document.');
  }

  // Enqueue the document for processing
  try {
    await apiClient.post(`/documents/${createDocumentResponse.id}/enqueue`);
  } catch {
    logger.error({ message: 'Failed to enqueue document for processing.' });
  }

  return createDocumentResponse;
}

export function useFetchDocumentProcessingEvents(
  params: ListDocumentProcessingEventsQuery,
  enabled?: boolean,
) {
  return useQuery({
    queryKey: ['document-processing-events', params],
    queryFn: async () => {
      const { data } =
        await apiClient.get<ListDocumentProcessingEventsResponse>(
          '/document-processing-events',
          {
            params,
          },
        );
      const validatedData =
        ListDocumentProcessingEventsResponseSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      }
      logger.warn({
        message: 'Failed to fetch document processing events',
        error: validatedData.error,
      });
      return data;
    },
    enabled,
  });
}
