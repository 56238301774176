import { type ColumnType } from 'antd/es/table';

import { type DocumentProcessingEventType } from '@mai/types';

import StackedDateTime from '../StackedDateTime';
import Table from '../Table';

import { useFetchDocumentProcessingEvents } from '@queries/documents';
import { renderDate } from '@utils/dates';

type DocumentProcessingEventRow = {
  id: string;
  type: DocumentProcessingEventType;
  document_id: string;
  createdAt: Date;
};

const DocumentProcessingEventTable = ({
  teamId,
  documentIds,
  size,
}: {
  teamId: string;
  documentIds?: string[];
  size?: 'small' | 'middle' | 'large';
}) => {
  const documentProcessingEventsQuery = useFetchDocumentProcessingEvents(
    {
      documentIds,
    },
    !!teamId,
  );

  const columns: ColumnType<DocumentProcessingEventRow>[] = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 300,
      render: (type: DocumentProcessingEventType) => {
        switch (type) {
          case 'ocr_finished':
            return 'Text Extraction Finished';
          case 'title_extraction_finished':
            return 'Title Extraction Finished';
          case 'post_processing_finished':
            return 'Post Processing Finished';
          case 'classification_finished':
            return 'Classification Finished';
          case 'embeddings_finished':
            return 'Embeddings Finished';
          case 'statement_extraction_finished':
            return 'Statement Extraction Finished';
          case 'table_of_contents_finished':
            return 'Table of Contents Finished';
        }
        throw new Error(`Unknown document processing event type: ${type}`);
      },
    },
    {
      title: 'Occurred At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => {
        if (size !== 'small') {
          return <StackedDateTime dateString={createdAt} />;
        }
        return renderDate(createdAt);
      },
    },
  ];

  const dataSource =
    documentProcessingEventsQuery.data?.map((event) => ({
      id: event.id,
      type: event.eventType,
      document_id: event.documentId,
      createdAt: event.createdAt,
    })) ?? [];

  return (
    <Table<DocumentProcessingEventRow>
      pagination={false}
      dataSource={dataSource}
      loading={documentProcessingEventsQuery.isLoading}
      columns={columns}
      size={size}
      rowKey={(record) => record.id}
    />
  );
};

export default DocumentProcessingEventTable;
