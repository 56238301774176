import BulbOutlined from '@ant-design/icons/BulbOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import FullscreenOutlined from '@ant-design/icons/FullscreenOutlined';
import SyncOutlined from '@ant-design/icons/SyncOutlined';
import { useQueryClient } from '@tanstack/react-query';
import Descriptions from 'antd/es/descriptions';
import Dropdown from 'antd/es/dropdown';
import message from 'antd/es/message';
import Modal from 'antd/es/modal';
import mime from 'mime';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { type CreateInsightRequest, type InsightRequest } from '@mai/types';

import ActiveTeamAlert from '@components/ActiveTeamAlert';
import ContentContainer from '@components/ContentContainer';
import FileRenderer from '@components/FileRenderer';
import SectionContainer from '@components/SectionContainer';
import DocumentExtractionsTable from '@components/tables/DocumentExtractionsTable';
import DocumentLinksTable from '@components/tables/DocumentLinksTable';
import DocumentProcessingEventTable from '@components/tables/DocumentProcessingEventsTable';
import { useFetchDocumentQuery } from '@queries/documents';
import { apiClient } from '@queries/index';
import { useTeamIsActive } from '@utils/billing';
import { logger } from '@utils/logger';
import { useDocumentId, useTeamId } from '@utils/router';

const FullScreenModal = styled(Modal)`
  height: 100vh;
  max-width: 100vw !important;
  top: 0;
  padding: 0;
  margin: 0 !important;
  .ant-modal-content {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }
  .ant-modal-body {
    height: calc(100vh - 108px);
    padding: 0;
  }
`;

const TeamDocumentPage = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const teamId = useTeamId();
  const documentId = useDocumentId();
  const teamIsActive = useTeamIsActive();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isArchiveModalVisible, setIsArchiveModalVisible] = useState(false);
  const [isUnarchiveModalVisible, setIsUnarchiveModalVisible] = useState(false);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [messageApi, messageContext] = message.useMessage();

  const fetchDocumentQuery = useFetchDocumentQuery(documentId);
  const document = fetchDocumentQuery.data;

  const cta = useMemo(() => {
    if (!document || !teamId) {
      return null;
    }

    return (
      <div>
        <Dropdown.Button
          type="primary"
          onClick={async () => {
            const loadingMessage = messageApi.loading('Loading insights...');
            try {
              const { data } = await apiClient.post<InsightRequest>(
                `/insight-requests`,
                {
                  links: [{ id: document.id, type: 'document' }],
                  teamId,
                  analysisType: 'coherence',
                } satisfies CreateInsightRequest,
              );
              loadingMessage();
              void messageApi.success('Insight request created successfully');
              navigate(`/team/${teamId}/insights/${data.id}`);
            } catch (e) {
              logger.error({
                message: 'Failed to create insight request',
                error: e,
              });
              loadingMessage();
              void messageApi.error('Failed to create insight request');
            }
          }}
          menu={{
            items: [
              {
                key: 'download',
                label: 'Download',
                icon: <DownloadOutlined />,
                onClick: () => {
                  window.open(document.downloadUrl);
                },
              },
              {
                key: 'preview',
                label: 'Preview',
                icon: <FullscreenOutlined />,
                onClick: () => {
                  setIsPreviewModalVisible(true);
                },
              },
              {
                key: 'edit',
                label: 'Edit',
                icon: <EditOutlined />,
                onClick: () => {
                  navigate('edit');
                },
              },
              {
                key: 're-sync',
                icon: <SyncOutlined />,
                disabled: !teamIsActive,
                label: 'Re-sync',
                onClick: async () => {
                  try {
                    await apiClient.post(`/documents/${document.id}/enqueue`);
                    void messageApi.success('Document re-synced successfully.');
                    void queryClient.invalidateQueries({
                      queryKey: ['documents'],
                    });
                  } catch (e) {
                    logger.error({
                      message: 'Failed to re-sync document',
                      error: e,
                    });
                    void messageApi.error(
                      'Failed to re-sync document, please try again.',
                    );
                    return;
                  }
                },
              },
              {
                key: 'delete',
                label: 'Delete',
                danger: true,
                icon: <DeleteOutlined />,
                onClick: () => {
                  setIsDeleteModalVisible(true);
                },
              },
            ],
          }}
        >
          <BulbOutlined />
          Insights
        </Dropdown.Button>
      </div>
    );
  }, [document, teamId, teamIsActive, messageApi, navigate, queryClient]);

  if (fetchDocumentQuery.isLoading) {
    return <ContentContainer.Loading />;
  }

  if (fetchDocumentQuery.isError || !document || !teamId) {
    return <ContentContainer.Error />;
  }

  const onConfirmDelete = async () => {
    setIsSubmitting(true);
    try {
      await apiClient.delete(`/documents/${documentId}`);
      setIsDeleteModalVisible(false);
      navigate('..');
      await queryClient.invalidateQueries({
        queryKey: ['documents'],
      });
      void message.success('Document deleted successfully.');
    } catch (error) {
      logger.warn({
        message: 'Failed to delete document',
        error,
      });
      void messageApi.error('Failed to delete document');
    } finally {
      setIsSubmitting(false);
    }
  };

  const onClickArchive = async () => {
    setIsSubmitting(true);
    try {
      await apiClient.patch(`/documents/${documentId}`, { archived: true });
      setIsArchiveModalVisible(false);
      navigate('..');
      void message.success('Document archived successfully.');
    } catch (error) {
      logger.warn({
        message: 'Failed to archive document',
        error,
      });
      void messageApi.error('Failed to archive document');
    } finally {
      setIsSubmitting(false);
    }
  };

  const onClickUnarchive = async () => {
    setIsSubmitting(true);
    try {
      await apiClient.patch(`/documents/${documentId}`, { archived: false });
      setIsUnarchiveModalVisible(false);
      navigate('..');
      void message.success('Document unarchived successfully.');
    } catch (error) {
      logger.warn({
        message: 'Failed to unarchive document',
        error,
      });
      void messageApi.error('Failed to unarchive document');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ContentContainer>
      {messageContext}
      <FullScreenModal
        title={document.title}
        open={isPreviewModalVisible}
        onCancel={() => setIsPreviewModalVisible(false)}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <FileRenderer
          documentId={document.id}
          url={document.viewUrl}
          fileType={document.fileType}
        />
      </FullScreenModal>
      <Modal
        title="Delete Document"
        open={isDeleteModalVisible}
        onOk={onConfirmDelete}
        okText="Delete"
        okType="danger"
        onCancel={() => setIsDeleteModalVisible(false)}
        okButtonProps={{ loading: isSubmitting }}
      >
        Are you sure you want to delete this document?
      </Modal>
      <Modal
        title="Archive Document"
        open={isArchiveModalVisible}
        onOk={onClickArchive}
        okText="Archive"
        onCancel={() => setIsArchiveModalVisible(false)}
        okButtonProps={{ loading: isSubmitting }}
      >
        Are you sure you want to archive this document?
      </Modal>
      <Modal
        title="Unarchive Document"
        open={isUnarchiveModalVisible}
        onOk={onClickUnarchive}
        okText="Unarchive"
        onCancel={() => setIsUnarchiveModalVisible(false)}
        okButtonProps={{ loading: isSubmitting }}
      >
        Are you sure you want to unarchive this document?
      </Modal>
      <ContentContainer.Header
        title={document.title}
        subtitle={
          teamIsActive ? undefined : <ActiveTeamAlert teamId={teamId} />
        }
        breadcrumbs={[
          { label: 'Home', to: `/team/${teamId}` },
          { label: 'Documents', to: `/team/${teamId}/documents` },
          { label: document.title },
        ]}
        cta={cta}
      />
      <SectionContainer title="Details">
        <Descriptions column={1}>
          <Descriptions.Item label="Title">{document.title}</Descriptions.Item>
          <Descriptions.Item label="Created At">
            {document.createdAt.toLocaleString()}
          </Descriptions.Item>
          <Descriptions.Item label="File Name">
            {document.fileName}
          </Descriptions.Item>
          <Descriptions.Item label="File Type">
            {mime.getExtension(document.fileType)?.toUpperCase() || 'Unknown'}
          </Descriptions.Item>
          <Descriptions.Item label="Description" span={2}>
            {document.description || 'No description'}
          </Descriptions.Item>
        </Descriptions>
      </SectionContainer>
      <SectionContainer title="Links">
        <DocumentLinksTable documentId={document.id} size="small" />
      </SectionContainer>
      <SectionContainer title="Processing History">
        <DocumentProcessingEventTable
          teamId={teamId}
          documentIds={[document.id]}
          size="small"
        />
      </SectionContainer>
      <SectionContainer title="Extracted Data">
        <DocumentExtractionsTable
          documentId={document.id}
          size="small"
          teamId={teamId}
        />
      </SectionContainer>
    </ContentContainer>
  );
};

export default TeamDocumentPage;
