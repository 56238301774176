import { z } from 'zod';

import { commaDelimitedList } from './utils';

export const EMBEDDING_VECTOR_SIZE = 1024;

const EmbeddingLinkSchema = z.object({
  id: z.string(),
  type: z.literal('documentExtraction'),
  startOffset: z.number(),
  endOffset: z.number(),
});

export const EmbeddingSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  embedding: z.array(z.number()),
  links: z.array(EmbeddingLinkSchema),
});

export type Embedding = z.infer<typeof EmbeddingSchema>;

export const ListEmbeddingsQuerySchema = z.object({
  embeddingIds: commaDelimitedList().optional(),
  documentIds: commaDelimitedList().optional(),
  documentExtractionIds: commaDelimitedList().optional(),
  query: z
    .object({
      value: z.string().optional(),
      minSimilarity: z.number().optional(),
    })
    .optional(),
  limit: z.number().optional(),
});

export type ListEmbeddingsQuery = z.infer<typeof ListEmbeddingsQuerySchema>;

export const ListEmbeddingsResponseSchema = z.array(EmbeddingSchema);

export type ListEmbeddingsResponse = z.infer<
  typeof ListEmbeddingsResponseSchema
>;
