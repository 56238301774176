import Alert from 'antd/es/alert';
import mime from 'mime';
import { useEffect, useState } from 'react';

import CenterSpin from './CenterSpin';

import { apiClient } from '@queries/index';

const PdfRenderer = ({ url }: { url: string }) => {
  return (
    <iframe
      src={url}
      style={{ width: '100%', height: '100%', minHeight: '800px' }}
      frameBorder="0"
    />
  );
};

const DocxRenderer = ({ url }: { url: string }) => {
  const [srcDoc, setSrcDoc] = useState('');
  useEffect(() => {
    void apiClient.get(url).then((res) => setSrcDoc(res.data));
  }, [url]);

  if (!srcDoc) {
    return <CenterSpin />;
  }
  return (
    <iframe
      srcDoc={srcDoc}
      style={{ width: '100%', height: '100%', minHeight: '800px' }}
      frameBorder="0"
    />
  );
};

const FileRenderer = ({
  documentId,
  url,
  fileType,
}: {
  documentId: string;
  url: string;
  fileType: string;
}) => {
  const fileExtension = mime.getExtension(fileType);
  if (fileType.includes('pdf')) {
    return <PdfRenderer url={url} />;
  }
  if (
    fileType.includes(
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    )
  ) {
    return <DocxRenderer url={`/documents/${documentId}/render`} />;
  }
  return (
    <Alert
      type="warning"
      showIcon
      message="File type not yet supported"
      description={`We're sorry, but we don't yet support opening ${fileExtension?.toUpperCase()} files yet. Please download the file to view it.`}
    />
  );
};

export default FileRenderer;
