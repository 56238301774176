import { z } from 'zod';

import { commaDelimitedList } from './utils';

export const TeamNameSchema = z
  .string()
  .min(1, 'Team name must have a length > 0.');

export const TeamSummarySchema = z.object({
  id: z.string(),
  name: z.string(),
});

export type TeamSummary = z.infer<typeof TeamSummarySchema>;

export const TeamDetailsSchema = TeamSummarySchema.extend({
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export type TeamDetails = z.infer<typeof TeamDetailsSchema>;

export const GetTeamResponseBodySchema = TeamDetailsSchema.merge(
  z.object({
    isActive: z.boolean(),
  }),
);

export type GetTeamResponseBody = z.infer<typeof GetTeamResponseBodySchema>;

export const CreateTeamRequestSchema = z.object({
  name: TeamNameSchema,
});

export type CreateTeamRequest = z.infer<typeof CreateTeamRequestSchema>;

export const UpdateTeamRequestSchema = z.object({
  name: TeamNameSchema.optional(),
});

export type UpdateTeamRequest = z.infer<typeof UpdateTeamRequestSchema>;

export const ListTeamsRequestSchema = z.object({
  name: TeamNameSchema.optional(),
  teamIds: commaDelimitedList().optional(),
  teamUserIds: commaDelimitedList().optional(),
});

export type ListTeamsRequest = z.infer<typeof ListTeamsRequestSchema>;

export const ListTeamsResponseSchema = z.array(TeamSummarySchema);

export type ListTeamsResponse = z.infer<typeof ListTeamsResponseSchema>;

export type GetTeamRequestSchema = {
  teamId: string;
};

export const GetTeamRequestSchema = z.object({
  teamId: TeamNameSchema,
});

export type GetTeamRequest = z.infer<typeof GetTeamRequestSchema>;
