import { z } from 'zod';

import { commaDelimitedList } from './utils';

export const AnalysisRequestResultSchema = z.object({
  id: z.string(),
  analysisRequestId: z.string(),
  analysisRequestResourceId: z.string(),
  analysisRequestFacetId: z.string(),
  analysisRequestRunId: z.string(),
  value: z.string(),
  reasoning: z.string().nullable(),
  createdAt: z.coerce.date(),
});

export type AnalysisRequestResult = z.infer<typeof AnalysisRequestResultSchema>;

export const ListAnalysisRequestResultQuerySchema = z.object({
  analysisRequestResultIds: commaDelimitedList().optional(),
  analysisRequestIds: commaDelimitedList().optional(),
  analysisRequestResourceIds: commaDelimitedList().optional(),
  analysisRequestFacetIds: commaDelimitedList().optional(),
});

export type ListAnalysisRequestResultQuery = z.infer<
  typeof ListAnalysisRequestResultQuerySchema
>;

export const ListAnalysisRequestResultResponseSchema = z.array(
  AnalysisRequestResultSchema,
);

export type ListAnalysisRequestResultResponse = z.infer<
  typeof ListAnalysisRequestResultResponseSchema
>;
