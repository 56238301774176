import { type ColumnType } from 'antd/es/table';
import { Link } from 'react-router-dom';

import Table from '@components/Table';
import { useFetchUsers } from '@queries/users';
import { renderDate } from '@utils/dates';

type UserRow = {
  userId: string;
  name: string;
};

const UsersTable = ({ size }: { size?: 'large' | 'middle' | 'small' }) => {
  const usersQuery = useFetchUsers({}, true);

  const columns: ColumnType<UserRow>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, row) => {
        return <Link to={`/profile/${row.userId}`}>{name}</Link>;
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => renderDate(createdAt),
    },
  ];

  const dataSource =
    usersQuery.data?.map((user) => ({
      userId: user.id,
      name: user.name,
    })) ?? [];

  return (
    <Table
      loading={usersQuery.isLoading}
      columns={columns}
      size={size}
      dataSource={dataSource}
      rowKey="userId"
    />
  );
};

export default UsersTable;
