import { QueryClientProvider } from '@tanstack/react-query';
import App from 'antd/es/app';
import ConfigProvider, { type ThemeConfig } from 'antd/es/config-provider';
import Spin from 'antd/es/spin';
import theme from 'antd/es/theme';
import { Suspense, createContext, lazy, useMemo } from 'react';
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import tinyColor from 'tinycolor2';

import ThemeConfigProvider from './context/ThemeContext';
import { queryClient } from './globals';
import Root from './pages';

import AdminLayout from '@components/AdminLayout';
import ContentContainer from '@components/ContentContainer';
import ProfileLayout from '@components/ProfileLayout';
import EmbedLayout from '@components/layouts/EmbedLayout';
import UnauthenticatedLayout from '@components/layouts/UnauthenticatedLayout';
import Home from '@pages/Home';
import RootRedirect from '@pages/RootRedirect';
import UnauthenticatedRoot from '@pages/UnauthenticatedRoot';
import AdminPage from '@pages/admin';
import LoginPage from '@pages/auth/login';
import RegisterPage from '@pages/auth/register';
import RegisterPending from '@pages/auth/register/pending';
import RegisterVerification from '@pages/auth/register/verification';
import ResetPassword from '@pages/auth/reset-password';
import ResetPasswordCallback from '@pages/auth/reset-password/callback';
import ResetPasswordSent from '@pages/auth/reset-password/sent';
import ResetPasswordSuccess from '@pages/auth/reset-password/success';
import CookiePolicyPage from '@pages/cookie-policy';
import EmbedPage from '@pages/embed';
import EmbedConversationPage from '@pages/embed/conversation';
import PrivacyPolicyPage from '@pages/privacy-policy';
import ProfilePage from '@pages/profile';
import UserProfilePage from '@pages/profile/:userId';
import TeamsRoot from '@pages/team';
import TeamRoot from '@pages/team/:teamId';
import AnalyzePage from '@pages/team/:teamId/analyze';
import AnalyzeDetailsPage from '@pages/team/:teamId/analyze/:analysisRequestId';
import AssistantPage from '@pages/team/:teamId/assistant';
import AssistantConversationPage from '@pages/team/:teamId/assistant/:conversationId';
import AssistantHistoryPage from '@pages/team/:teamId/assistant/history';
import NewAssistantPage from '@pages/team/:teamId/assistant/new';
import BillingOverviewPage from '@pages/team/:teamId/billing/overview';
import BillingUsageHistoryPage from '@pages/team/:teamId/billing/usage-history';
import TeamDocumentPage from '@pages/team/:teamId/documents/:documentId';
import EditDocumentPage from '@pages/team/:teamId/documents/:documentId/edit';
import CreateDocumentPage from '@pages/team/:teamId/documents/new';
import DocumentsOverview from '@pages/team/:teamId/documents/overview';
import InsightRequestDetailsPage from '@pages/team/:teamId/insights/:insightRequestId';
import InsightsHistoryPage from '@pages/team/:teamId/insights/history';
import InsightsNewPage from '@pages/team/:teamId/insights/new';
import InsightsOverviewPage from '@pages/team/:teamId/insights/overview';
import Integrations from '@pages/team/:teamId/integrations';
import TeamManageRoot from '@pages/team/:teamId/manage';
import ManageTeamGeneralPage from '@pages/team/:teamId/manage/general';
import EditTeamDetailsPage from '@pages/team/:teamId/manage/general/edit';
import ManageTeamUsersPage from '@pages/team/:teamId/manage/users';
import TeamUserDetailPage from '@pages/team/:teamId/manage/users/:userId';
import ProjectDetailsPage from '@pages/team/:teamId/projects/:projectId';
import ProjectsOverviewPage from '@pages/team/:teamId/projects/overview';
import SearchPage from '@pages/team/:teamId/search';
import StripeCheckoutSuccessPage from '@pages/team/:teamId/stripe-checkout/success';
import TeamRootRedirect from '@pages/team/TeamRootRedirect';
import ManageTeams from '@pages/team/manage';
import TermsAndConditionsPage from '@pages/terms-and-conditions';
import { useLocalStorage } from '@utils/theme';

const Labs = lazy(() => import('@pages/labs'));

const NotFoundPage = () => {
  return (
    <UnauthenticatedLayout>
      <ContentContainer.NotFound />
    </UnauthenticatedLayout>
  );
};

export type ThemeContextValues = 'dark' | 'light';

export const ThemeContext = createContext<{
  value: ThemeContextValues;
  setValue: (value: ThemeContextValues) => void;
}>({
  value: 'dark',
  setValue: () => {},
});

const Fallback = () => (
  <ContentContainer>
    <Spin />
  </ContentContainer>
);

const router = createBrowserRouter([
  {
    path: '/embed',
    element: <EmbedLayout />,
    children: [
      {
        path: '',
        element: <EmbedPage />,
      },
      {
        path: 'conversation',
        element: <EmbedConversationPage />,
      },
    ],
  },
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '',
        element: <RootRedirect />,
      },
      {
        path: 'terms-and-conditions',
        element: <TermsAndConditionsPage />,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicyPage />,
      },
      {
        path: 'cookie-policy',
        element: <CookiePolicyPage />,
      },
      {
        path: 'labs',
        element: (
          <Suspense fallback={<Fallback />}>
            <Labs />
          </Suspense>
        ),
      },
      {
        path: 'auth',
        element: (
          <Suspense>
            <UnauthenticatedRoot />
          </Suspense>
        ),
        children: [
          {
            path: 'login',
            element: (
              <Suspense fallback={<Fallback />}>
                <LoginPage />
              </Suspense>
            ),
          },
          {
            path: 'reset-password',
            children: [
              {
                path: '',
                element: (
                  <Suspense fallback={<Fallback />}>
                    <ResetPassword />
                  </Suspense>
                ),
              },
              {
                path: 'sent',
                element: (
                  <Suspense fallback={<Fallback />}>
                    <ResetPasswordSent />
                  </Suspense>
                ),
              },
              {
                path: 'callback',
                element: (
                  <Suspense fallback={<Fallback />}>
                    <ResetPasswordCallback />
                  </Suspense>
                ),
              },
              {
                path: 'success',
                element: (
                  <Suspense fallback={<Fallback />}>
                    <ResetPasswordSuccess />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: 'register',
            element: (
              <Suspense fallback={<Fallback />}>
                <Outlet />
              </Suspense>
            ),
            children: [
              {
                path: '',
                element: (
                  <Suspense fallback={<Fallback />}>
                    <RegisterPage />
                  </Suspense>
                ),
              },
              {
                path: 'verification',
                element: (
                  <Suspense fallback={<Fallback />}>
                    <RegisterVerification />
                  </Suspense>
                ),
              },
              {
                path: 'pending',
                element: (
                  <Suspense fallback={<Fallback />}>
                    <RegisterPending />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: '*',
            element: (
              <Suspense fallback={<Fallback />}>
                <Navigate to="/auth/login" replace />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'admin',
        element: (
          <AdminLayout>
            <Outlet />
          </AdminLayout>
        ),
        children: [
          {
            path: '',
            element: (
              <Suspense fallback={<Fallback />}>
                <AdminPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'profile',
        element: (
          <ProfileLayout>
            <Outlet />
          </ProfileLayout>
        ),
        children: [
          {
            path: '',
            element: (
              <Suspense fallback={<Fallback />}>
                <ProfilePage />
              </Suspense>
            ),
          },
          {
            path: ':userId',
            element: (
              <Suspense fallback={<Fallback />}>
                <UserProfilePage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'team',
        element: <TeamsRoot />,
        children: [
          {
            path: '',
            element: <TeamRootRedirect />,
          },
          {
            path: 'manage',
            element: (
              <Suspense fallback={<Fallback />}>
                <ManageTeams />
              </Suspense>
            ),
          },
          {
            path: ':teamId',
            element: <TeamRoot />,
            children: [
              {
                path: '',
                element: (
                  <Suspense fallback={<Fallback />}>
                    <Home />
                  </Suspense>
                ),
              },
              {
                path: 'stripe-checkout',
                children: [
                  {
                    path: 'success',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <StripeCheckoutSuccessPage />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: 'insights',
                children: [
                  {
                    path: '',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <Navigate to="overview" replace />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'overview',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <InsightsOverviewPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'history',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <InsightsHistoryPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'new',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <InsightsNewPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: ':insightRequestId',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <InsightRequestDetailsPage />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: 'assistant',
                children: [
                  {
                    path: '',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <AssistantPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'new',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <NewAssistantPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'history',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <AssistantHistoryPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: ':conversationId',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <AssistantConversationPage />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: 'search',
                element: (
                  <Suspense fallback={<Fallback />}>
                    <SearchPage />
                  </Suspense>
                ),
              },
              {
                path: 'analyze',
                children: [
                  {
                    path: '',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <AnalyzePage />
                      </Suspense>
                    ),
                  },
                  {
                    path: ':analysisRequestId',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <AnalyzeDetailsPage />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: 'billing',
                children: [
                  {
                    path: '',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <Navigate to="overview" replace />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'overview',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <BillingOverviewPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'usage-history',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <BillingUsageHistoryPage />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: 'manage',
                children: [
                  {
                    path: '',
                    element: <TeamManageRoot />,
                  },
                  {
                    path: 'general',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <ManageTeamGeneralPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'general/edit',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <EditTeamDetailsPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'users',
                    children: [
                      {
                        path: '',
                        element: (
                          <Suspense fallback={<Fallback />}>
                            <ManageTeamUsersPage />
                          </Suspense>
                        ),
                      },
                      {
                        path: ':userId',
                        element: (
                          <Suspense fallback={<Fallback />}>
                            <TeamUserDetailPage />
                          </Suspense>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: 'integrations',
                element: (
                  <Suspense fallback={<Fallback />}>
                    <Integrations />
                  </Suspense>
                ),
              },
              {
                path: 'projects',
                children: [
                  {
                    path: '',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <Navigate to="overview" replace />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'overview',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <ProjectsOverviewPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: ':projectId',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <ProjectDetailsPage />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: 'documents',
                children: [
                  {
                    path: '',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <Navigate to="overview" replace />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'overview',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <DocumentsOverview />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'new',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <CreateDocumentPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: ':documentId',
                    children: [
                      {
                        path: '',
                        element: (
                          <Suspense fallback={<Fallback />}>
                            <TeamDocumentPage />
                          </Suspense>
                        ),
                      },
                      {
                        path: 'edit',
                        element: (
                          <Suspense fallback={<Fallback />}>
                            <EditDocumentPage />
                          </Suspense>
                        ),
                      },
                    ],
                  },
                  {
                    path: '*',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <NotFoundPage />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: '*',
                element: (
                  <Suspense fallback={<Fallback />}>
                    <NotFoundPage />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'error',
    element: (
      <UnauthenticatedLayout>
        <ContentContainer.Error />
      </UnauthenticatedLayout>
    ),
  },
  {
    path: '*',
    element: (
      <Suspense fallback={<Fallback />}>
        <NotFoundPage />
      </Suspense>
    ),
  },
]);

const MyApp = () => {
  const [themeContextValue, setThemeContextValue] =
    useLocalStorage<ThemeContextValues>('theme', 'dark');

  const themeConfiguration: ThemeConfig = useMemo(() => {
    // Brand Color, use this for primary components and accents
    const primaryColor = '#5954cc';

    // We tone the error color down, it's too bright
    const colorError = tinyColor('#ff4d4f').darken(10).toString();

    // Secondary Color, we use this for non-primary buttons, selected items etc...
    const secondaryColor =
      themeContextValue === 'dark'
        ? '#3d3d3d'
        : tinyColor(primaryColor).lighten(30).toString();
    const backgroundColor =
      themeContextValue === 'dark' ? '#121212' : '#f0f2f5';
    const textColor = themeContextValue === 'dark' ? '#f0f2f5' : '#121212';
    const colorFillSecondary =
      themeContextValue === 'dark' ? '#817de4' : '#c0bef1';

    // Derived Colours
    const surfaceColor =
      themeContextValue === 'dark'
        ? tinyColor(backgroundColor).lighten(5).toString()
        : tinyColor(backgroundColor).darken(2).toString();
    const borderColor = tinyColor(primaryColor).darken(5).toString();

    return {
      algorithm:
        themeContextValue === 'dark'
          ? theme.darkAlgorithm
          : theme.defaultAlgorithm,
      components: {
        Button: {
          dangerShadow: colorError,
        },
        Layout: {
          headerBg: backgroundColor,
          siderBg: backgroundColor,
          margin: 0,
          padding: 0,
          headerHeight: 55,
        },
        Menu: {
          itemSelectedBg: secondaryColor,
          itemSelectedColor: textColor,
          itemHeight: 35,
        },
        Tag: {
          defaultBg: surfaceColor,
        },
        Card: {
          colorBgContainer:
            themeContextValue === 'dark'
              ? tinyColor(surfaceColor).darken(2).toString()
              : tinyColor(surfaceColor).lighten(2).toString(),
        },
      },
      token: {
        colorPrimary: primaryColor,
        colorBorderBg: backgroundColor,
        colorBgBase: backgroundColor,
        colorBgContainer: surfaceColor,
        colorBgLayout: backgroundColor,
        colorBorder: borderColor,
        colorTextBase: textColor,
        colorFillSecondary,
        colorError,
      },
    } as ThemeConfig;
  }, [themeContextValue]);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeConfigProvider themeConfiguration={themeConfiguration}>
        <ThemeContext.Provider
          value={{
            value: themeContextValue,
            setValue: setThemeContextValue,
          }}
        >
          <ConfigProvider theme={themeConfiguration}>
            <App>
              <RouterProvider router={router} />
            </App>
          </ConfigProvider>
        </ThemeContext.Provider>
      </ThemeConfigProvider>
    </QueryClientProvider>
  );
};

export { MyApp as App };
