import { useQueryClient } from '@tanstack/react-query';
import Alert from 'antd/es/alert';
import App from 'antd/es/app';
import Button from 'antd/es/button';
import Checkbox from 'antd/es/checkbox';
import Divider from 'antd/es/divider';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import theme from 'antd/es/theme';
import Typography from 'antd/es/typography';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { LoginResponseSchema } from '@mai/types';

import { apiClient } from '../../../queries';

import UnauthCard from '@components/UnauthCard';
import { track } from '@utils/mixpanel';

type FormData = {
  email: string;
  password: string;
};

const LoginPage = () => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm<FormData>();
  const [isLoading, setIsLoading] = useState(false);
  const { message } = App.useApp();
  const {
    token: { colorFillSecondary },
  } = theme.useToken();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onFinish = async ({ email, password }: FormData) => {
    setIsLoading(true);
    try {
      const { data } = await apiClient.post('/auth/login', {
        email,
        password,
      });
      const validatedData = LoginResponseSchema.parse(data);
      localStorage.setItem('sessionToken', validatedData.token);
      void message.success('Login successful');
      track('LOGGED_IN', {
        email,
      });
      await queryClient.invalidateQueries({ queryKey: ['auth'] });
    } catch (error) {
      let errorMessage: string | null = null;
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          errorMessage =
            'Invalid email or password, please check and try again.';
        } else if (error.response?.status === 429) {
          errorMessage = 'Too many login attempts, please try again later';
        } else {
          errorMessage =
            error.response?.data?.message ||
            error.response?.data?.errors[0]?.message ||
            'Invalid email or password';
          void message.error(errorMessage);
          setErrorMessage(errorMessage);
        }
      }
      if (errorMessage) setErrorMessage(errorMessage);
      else void message.error('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        marginLeft: '16px',
      }}
    >
      <UnauthCard>
        <Typography.Title level={3}>
          {'Sign in to your account'}
        </Typography.Title>
        {errorMessage && (
          <Alert
            message={errorMessage}
            type="warning"
            showIcon
            style={{
              marginBottom: '16px',
            }}
          />
        )}
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: 'Please input a valid email address',
                pattern: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g,
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            style={{
              marginBottom: '0px',
            }}
            rules={[
              {
                required: true,
                message: 'Please input your password',
              },
            ]}
          >
            <Input.Password size="large" />
          </Form.Item>
          <div style={{ height: '8px' }} />
          <Typography.Text>
            <Link to="/auth/reset-password">{'Forgot Password?'}</Link>
          </Typography.Text>
          <div style={{ height: '8px' }} />
          <Form.Item>
            <Checkbox>{'Stay Signed in for 30 days'}</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button
              loading={isLoading}
              type="primary"
              htmlType="submit"
              style={{ width: '100%' }}
              size="large"
            >
              {'Sign In'}
            </Button>
          </Form.Item>
        </Form>
        <Divider>{'OR'}</Divider>
        <Link to="/auth/register">
          <Button
            color={colorFillSecondary}
            style={{ width: '100%' }}
            size="large"
          >
            {'Sign up for a new account'}
          </Button>
        </Link>
      </UnauthCard>
    </div>
  );
};

export default LoginPage;
