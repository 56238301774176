import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import Button from 'antd/es/button';
import Typography from 'antd/es/typography';
import { Link } from 'react-router-dom';

import DocumentsImage from '@assets/undraw_personal_file.svg';
import ActiveTeamAlert from '@components/ActiveTeamAlert';
import ContentContainer from '@components/ContentContainer';
import { Hero } from '@components/Hero';
import DocumentsTable from '@components/tables/DocumentsTable';
import { useTeamIsActive } from '@utils/billing';
import { useTeamId } from '@utils/router';

const DocumentsOverview = () => {
  const teamId = useTeamId();
  const teamIsActive = useTeamIsActive();

  if (!teamId) {
    return <ContentContainer.Error />;
  }
  return (
    <ContentContainer showFullWidthToggle>
      <ContentContainer.Header
        title="Documents"
        subtitle={
          teamIsActive ? undefined : <ActiveTeamAlert teamId={teamId} />
        }
        breadcrumbs={[
          { label: 'Home', to: `/team/${teamId}` },
          { label: 'Documents' },
        ]}
        cta={
          <Link to={`/team/${teamId}/documents/new`}>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              disabled={!teamIsActive}
            >
              Create Document
            </Button>
          </Link>
        }
      />
      <Hero
        id="documents-overview"
        color={{
          light:
            'linear-gradient(90deg, rgba(98, 92, 221, 0.3) 0%, rgba(98, 92, 221, 0.2) 50%, rgba(98, 92, 221, 0.35) 100%)',
          dark: 'linear-gradient(90deg, rgba(52,49,101,0.6) 0%, rgba(52,49,101,0.9) 50%, rgba(52,49,101,0.45) 100%)',
        }}
        imgSrc={DocumentsImage}
        title="Upload and analyze your documents"
        extra={
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}
          >
            <div>
              <Typography.Text>
                Start creating documents for your team today, all documents
                uploaded will automatically be tagged and prepared for analysis.
              </Typography.Text>
            </div>
            <div
              style={{
                display: 'flex',
                gap: '0.5rem',
              }}
            >
              <Link to={`/team/${teamId}/documents/new`}>
                <Button
                  type="primary"
                  disabled={!teamIsActive}
                  style={{
                    boxShadow: '0px 0px 0px rgba(98, 92, 221, 0.2)',
                  }}
                >
                  Create Document
                </Button>
              </Link>
              <Link
                to="https://scribehow.com/shared/How_to_add_new_documents__2NBSyq5ySUe0n3s-e-Ri2w"
                target="_blank"
              >
                <Button
                  disabled={!teamIsActive}
                  style={{
                    boxShadow: '0px 0px 0px rgba(98, 92, 221, 0.2)',
                  }}
                >
                  Learn More
                </Button>
              </Link>
            </div>
          </div>
        }
      />
      <DocumentsTable teamIds={teamId ? [teamId] : []} />
    </ContentContainer>
  );
};

export default DocumentsOverview;
