import ControlOutlined from '@ant-design/icons/ControlOutlined';
import DatabaseOutlined from '@ant-design/icons/DatabaseOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import HistoryOutlined from '@ant-design/icons/HistoryOutlined';
import MoreOutlined from '@ant-design/icons/MoreOutlined';
import PauseCircleOutlined from '@ant-design/icons/PauseCircleOutlined';
import PlayCircleOutlined from '@ant-design/icons/PlayCircleOutlined';
import TableOutlined from '@ant-design/icons/TableOutlined';
import UndoOutlined from '@ant-design/icons/UndoOutlined';
import WarningOutlined from '@ant-design/icons/WarningOutlined';
import { useQueryClient } from '@tanstack/react-query';
import App from 'antd/es/app';
import Button from 'antd/es/button';
import Drawer from 'antd/es/drawer';
import Dropdown from 'antd/es/dropdown';
import Skeleton from 'antd/es/skeleton';
import Table from 'antd/es/table';
import { type ColumnType } from 'antd/es/table';
import Tag from 'antd/es/tag';
import Tooltip from 'antd/es/tooltip';
import Typography from 'antd/es/typography';
import startCase from 'lodash.startcase';
import { useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  type AnalysisRequestRun,
  type CreateAnalysisRequestRunBody,
  type UpdateAnalysisRequestRequestBody,
  type UpdateAnalysisRequestRunBody,
} from '@mai/types';

import AnalysisRequestStatus from './AnalysisRequestStatus';
import EditData from './EditData';
import EditDetails from './EditDetails';
import EditFacets from './EditFacets';
import RunHistoryList from './RunHistoryList';
import { useAnalysisRequestStatus } from './hooks';

import ContentContainer from '@components/ContentContainer';
import { useAnalysisRequestResultsQuery } from '@queries/analysis-request-results';
import { useAnalysisRequestRunsQuery } from '@queries/analysis-request-runs';
import {
  updateAnalysisRequest,
  useAnalysisRequestQuery,
} from '@queries/analysis-requests';
import { useFetchDocumentsQuery } from '@queries/documents';
import { apiClient } from '@queries/index';
import { subscribe, unsubscribe } from '@queries/websockets';
import { useSessionUserId } from '@utils/auth';
import { useTeamIsActive } from '@utils/billing';
import { usePrevious } from '@utils/hooks';
import { logger } from '@utils/logger';
import { track } from '@utils/mixpanel';

type AnalysisRow = {
  name: string;
  linkType: 'document';
  linkId: string;
  facetValues: {
    analysisRequestFacetId: string;
    value: string;
    reasoning: string | null;
  }[];
};

const FilterRowContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
`;

const FilterRowButtonGroupContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-right: 8px;
`;

const AnalysisRequestEditor = ({
  analysisRequestId,
  teamId,
}: {
  analysisRequestId: string;
  teamId: string;
}) => {
  const navigate = useNavigate();
  const { message, modal } = App.useApp();
  const queryClient = useQueryClient();
  const teamIsActive = useTeamIsActive();
  const sessionUserId = useSessionUserId();

  const [searchParams, setSearchParams] = useSearchParams();

  const isEditingFacets = searchParams.get('drawer') === 'edit-facets';
  const isFilteringData = searchParams.get('drawer') === 'filter-data';
  const isEditingDetails = searchParams.get('drawer') === 'edit-details';
  const isViewingHistory = searchParams.get('drawer') === 'run-history';

  const analysisRequestQuery = useAnalysisRequestQuery(analysisRequestId);
  const analysisRequest = analysisRequestQuery.data;

  const analysisRequestRunsQuery = useAnalysisRequestRunsQuery(
    {
      analysisRequestIds: analysisRequest ? [analysisRequest.id] : [],
    },
    !!analysisRequest,
  );
  const latestAnalysisRequestRun = analysisRequestRunsQuery.data?.sort(
    (a, b) => b.createdAt.getTime() - a.createdAt.getTime(),
  )[0];
  const analysisRequestRunId = latestAnalysisRequestRun?.id;

  const handleOpenDrawer = (
    drawer: 'edit-facets' | 'filter-data' | 'edit-details' | 'run-history',
  ) => {
    setSearchParams((prev) => {
      prev.set('drawer', drawer);
      return prev;
    });
  };

  const handleCloseDrawer = () => {
    setSearchParams((prev) => {
      prev.delete('drawer');
      return prev;
    });
  };

  const facets = analysisRequest?.facets ?? [];

  const { status } = useAnalysisRequestStatus({
    analysisRequest,
  }) ?? { status: 'not-started' };

  const columns: ColumnType<AnalysisRow>[] = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      width: '200px',
      render: (name, record) => {
        if (record.linkType === 'document') {
          return (
            <Link
              to={`/team/${teamId}/documents/${record.linkId}`}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block',
                maxWidth: '180px',
              }}
            >
              {name}
            </Link>
          );
        }
      },
    },
    {
      key: 'type',
      title: 'Type',
      dataIndex: 'linkType',
      width: '100px',
      render: (linkType) => {
        if (linkType === 'document') {
          return <Tag color="blue">Document</Tag>;
        }
        return startCase(linkType);
      },
    },
    ...facets.map(
      (facet) =>
        ({
          key: `facet-${facet.name}-${facet.type}`,
          ellipsis: true,
          width: 150,
          title: <Typography.Text>{facet.name}</Typography.Text>,
          render: (_, record) => {
            const thisFacet = record.facetValues.find(
              (facetValue) => facetValue.analysisRequestFacetId === facet.id,
            );
            const facetValue = thisFacet?.value;
            const reasoning = thisFacet?.reasoning;
            if (status === 'running' && !facetValue) {
              return (
                <Skeleton.Button
                  active
                  style={{ width: '100px', height: '15px' }}
                />
              );
            }
            if (!facetValue) {
              return '-';
            }

            return (
              (
                <div
                  style={{
                    display: 'flex',
                    gap: '0.5rem',
                  }}
                >
                  <Tooltip title={reasoning}>
                    <Typography.Text
                      ellipsis
                      style={{
                        maxWidth: '200px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {facetValue}
                    </Typography.Text>
                  </Tooltip>
                </div>
              ) ?? '-'
            );
          },
        }) satisfies ColumnType<AnalysisRow>,
    ),
    ...(facets.length === 0
      ? [
          {
            key: 'empty',
            title: (
              <Typography.Text type="secondary">
                <WarningOutlined
                  style={{
                    marginRight: '0.5rem',
                  }}
                />
                {'No Dimensions Selected'}
              </Typography.Text>
            ),
          },
        ]
      : []),
  ];

  const documentIds = analysisRequest?.resources
    .filter((resource) => resource.resourceType === 'document')
    .map((resource) => resource.resourceId);

  const documentsQuery = useFetchDocumentsQuery(
    {
      documentIds,
      pageSize: documentIds?.length,
    },
    !!documentIds?.length,
  );

  const analysisRequestResultsQuery = useAnalysisRequestResultsQuery(
    {
      analysisRequestIds: analysisRequest ? [analysisRequest.id] : [],
    },
    !!analysisRequest,
  );
  const analysisRequestResults = analysisRequestResultsQuery.data;

  const dataSource =
    analysisRequest?.resources.map((resource) => {
      const facetValues = analysisRequestResults
        ?.filter(
          ({ analysisRequestResourceId }) =>
            analysisRequestResourceId === resource.id,
        )
        .map(({ analysisRequestFacetId, value, reasoning }) => {
          return {
            analysisRequestFacetId,
            value,
            reasoning,
          };
        });

      if (resource.resourceType === 'document') {
        const document = documentsQuery.data?.data.find(
          (document) => document.id === resource.resourceId,
        );
        return {
          name: document?.title ?? 'Unknown',
          linkType: resource.resourceType,
          linkId: resource.resourceId,
          facetValues: facetValues ?? [],
        };
      }
      return {
        name: resource.resourceId,
        linkType: resource.resourceType,
        linkId: resource.resourceId,
        facetValues: facetValues ?? [],
      };
    }) ?? [];

  const previousAnalysisRequestRunId = usePrevious(analysisRequestRunId);
  useEffect(() => {
    if (
      previousAnalysisRequestRunId !== analysisRequestRunId &&
      previousAnalysisRequestRunId
    ) {
      unsubscribe('analysis_request_run_event', {
        analysisRequestRunId: previousAnalysisRequestRunId,
      });
    }
    if (!analysisRequestRunId) return;
    subscribe(
      'analysis_request_run_event',
      {
        analysisRequestRunId,
      },
      () => {
        // Refetch data as it progressively loads in
        void Promise.allSettled([
          void queryClient.invalidateQueries({
            queryKey: ['analysisRequestRuns'],
          }),
          void queryClient.invalidateQueries({
            queryKey: ['analysisRequestResults'],
          }),
        ]);
      },
    );
  }, [analysisRequestRunId, previousAnalysisRequestRunId, queryClient]);

  if (!teamId) {
    return <ContentContainer.Error />;
  }

  if (!analysisRequestId) {
    return <ContentContainer.Error />;
  }

  if (!analysisRequest || analysisRequestQuery.isLoading) {
    return <ContentContainer.Loading />;
  }

  const handleStartAnalysis = async () => {
    // Create a new analysis request run with the shouldRun flag set to true
    const loadingMessage = message.loading('Starting analysis...');
    try {
      const { data } = await apiClient.post<AnalysisRequestRun>(
        `/analysis-request-runs`,
        {
          analysisRequestId,
          shouldRun: true,
        } satisfies CreateAnalysisRequestRunBody,
      );
      logger.info(
        {
          analysisRequestRunId: data.id,
        },
        'Analysis started',
      );
      await queryClient.invalidateQueries({
        queryKey: ['analysisRequestRuns'],
      });
      void message.success('Analysis started');
      track('STARTED_ANALYSIS_REQUEST_RUN', {
        analysisRequestId,
        analysisRequestRunId,
        teamId,
        sessionUserId,
      });
    } catch {
      void message.error('Failed to start analysis');
    } finally {
      loadingMessage();
    }
  };

  const handleStopAnalysis = async () => {
    const loadingMessage = message.loading('Requesting stop...');
    try {
      await apiClient.patch<AnalysisRequestRun>(
        `/analysis-request-runs/${analysisRequestRunId}`,
        {
          shouldCancel: true,
        } satisfies UpdateAnalysisRequestRunBody,
      );
      loadingMessage();
      void message.success('Analysis stop requested');
      track('STOPPED_ANALYSIS_REQUEST_RUN', {
        analysisRequestId,
        analysisRequestRunId,
        teamId,
        sessionUserId,
      });
    } catch {
      loadingMessage();
      void message.error('Failed to request stop');
    }
  };

  const drawerWidth = 700;

  return (
    <>
      <Drawer
        title="Run History"
        open={isViewingHistory}
        onClose={() => {
          handleCloseDrawer();
        }}
        width={drawerWidth}
        destroyOnClose
      >
        <RunHistoryList analysisRequest={analysisRequest} />
      </Drawer>
      <Drawer
        title="Edit Title"
        open={isEditingDetails}
        onClose={() => {
          handleCloseDrawer();
        }}
        width={drawerWidth}
        destroyOnClose
      >
        <EditDetails
          initialAnalysisRequest={analysisRequest}
          onFinish={async (analysisRequest) => {
            const loadingMessage = message.loading('Updating details...');
            try {
              await updateAnalysisRequest(analysisRequestId, analysisRequest);
              await queryClient.invalidateQueries({
                queryKey: ['analysisRequest', analysisRequestId],
              });
              loadingMessage();
              void message.success('Details updated');
              handleCloseDrawer();
            } catch {
              loadingMessage();
              void message.error('Failed to update details');
            }
          }}
        />
      </Drawer>
      <Drawer
        title="Edit Dimensions"
        open={isEditingFacets}
        onClose={() => {
          handleCloseDrawer();
        }}
        width={drawerWidth}
        destroyOnClose
      >
        <EditFacets
          initialAnalysisRequest={analysisRequest}
          onCancel={handleCloseDrawer}
          onFinish={async (facets) => {
            const loadingMessage = message.loading('Updating dimensions...');
            try {
              await updateAnalysisRequest(analysisRequestId, {
                facets,
              });
              await queryClient.invalidateQueries({
                queryKey: ['analysisRequest', analysisRequestId],
              });
              void message.success('Dimensions updated');
              handleCloseDrawer();
            } catch {
              void message.error('Failed to update dimensions');
            } finally {
              loadingMessage();
            }
          }}
        />
      </Drawer>
      <Drawer
        title="Edit Sources"
        open={isFilteringData}
        onClose={() => {
          handleCloseDrawer();
        }}
        width={drawerWidth}
        closable
        destroyOnClose
      >
        <EditData
          teamId={teamId}
          analysisRequest={analysisRequest}
          onFinish={async (resources) => {
            const loadingMessage = message.loading('Updating data...');
            try {
              await apiClient.patch(`/analysis-requests/${analysisRequestId}`, {
                resources,
              } satisfies UpdateAnalysisRequestRequestBody);
              await queryClient.invalidateQueries({
                queryKey: ['analysisRequest', analysisRequestId],
              });
              loadingMessage();
              void message.success('Data updated');
              handleCloseDrawer();
            } catch {
              loadingMessage();
              void message.error('Failed to update data');
            }
          }}
        />
      </Drawer>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <FilterRowContainer>
          <FilterRowButtonGroupContainer>
            <Button
              size="small"
              icon={<ControlOutlined />}
              onClick={() => {
                handleOpenDrawer('edit-details');
                track('OPENED_ANALYSIS_REQUEST_EDIT_METADATA', {
                  analysisRequestId,
                  teamId,
                  sessionUserId,
                });
              }}
              disabled={!teamIsActive || status === 'running'}
            >
              Edit Title
            </Button>
            <Button
              size="small"
              icon={<TableOutlined />}
              onClick={() => {
                handleOpenDrawer('edit-facets');
                track('OPENED_ANALYSIS_REQUEST_EDIT_DATA', {
                  analysisRequestId,
                  teamId,
                  sessionUserId,
                });
              }}
              disabled={!teamIsActive || status === 'running'}
            >
              Edit Dimensions
            </Button>
            <Button
              size="small"
              icon={<DatabaseOutlined />}
              onClick={() => {
                handleOpenDrawer('filter-data');
                track('OPENED_ANALYSIS_REQUEST_EDIT_SOURCES', {
                  analysisRequestId,
                  teamId,
                  sessionUserId,
                });
              }}
              disabled={!teamIsActive || status === 'running'}
            >
              Edit Sources
            </Button>
          </FilterRowButtonGroupContainer>
          <FilterRowButtonGroupContainer>
            <AnalysisRequestStatus analysisRequest={analysisRequest} />
            <Dropdown.Button
              type={status === 'running' ? 'default' : 'primary'}
              size="small"
              icon={<MoreOutlined />}
              menu={{
                items: [
                  {
                    key: 'run-history',
                    icon: <HistoryOutlined />,
                    label: 'View Past Runs',
                    onClick: () => {
                      handleOpenDrawer('run-history');
                      track('OPENED_ANALYSIS_REQUEST_RUN_HISTORY', {
                        analysisRequestId,
                        teamId,
                        sessionUserId,
                      });
                    },
                  },
                  {
                    key: 'reset-results',
                    icon: <UndoOutlined />,
                    label: 'Reset Results',
                    disabled: status === 'running',
                    onClick: () => {
                      return modal.confirm({
                        icon: null,
                        title: 'Reset Analysis Results',
                        okText: 'Reset',
                        closable: true,
                        maskClosable: true,
                        okButtonProps: {
                          size: 'small',
                        },
                        cancelButtonProps: {
                          size: 'small',
                        },
                        content:
                          'Are you sure you want to reset the analysis results? This will delete all existing results and historical runs for this analysis.',
                        onOk: async () => {
                          const loadingMessage = message.loading(
                            'Resetting analysis results',
                          );
                          try {
                            await updateAnalysisRequest(analysisRequestId, {
                              shouldDeleteResults: true,
                            });
                            void message.success('Analysis results reset');
                            void queryClient.invalidateQueries({
                              queryKey: ['analysisRequestResults'],
                            });
                            void queryClient.invalidateQueries({
                              queryKey: ['analysisRequestRuns'],
                            });
                            track('CLEARED_ANALYSIS_REQUEST_RESULTS', {
                              analysisRequestId,
                              teamId,
                              sessionUserId,
                            });
                          } catch {
                            void message.error(
                              'Failed to reset analysis results',
                            );
                          } finally {
                            loadingMessage();
                          }
                        },
                      });
                    },
                  },
                  {
                    key: 'delete',
                    icon: <DeleteOutlined />,
                    label: 'Delete Analysis',
                    disabled: status === 'running',
                    onClick: () => {
                      return modal.confirm({
                        maskClosable: true,
                        onOk: async () => {
                          const loadingMessage = message.loading(
                            'Deleting analysis request',
                          );
                          try {
                            await apiClient.delete(
                              `/analysis-requests/${analysisRequestId}`,
                            );
                            void message.success('Analysis request deleted');
                            void queryClient.invalidateQueries({
                              queryKey: ['analysisRequests'],
                            });
                            navigate(`/team/${teamId}/analyze`);
                            track('DELETED_ANALYSIS_REQUEST', {
                              analysisRequestId,
                              teamId,
                              sessionUserId,
                            });
                          } catch {
                            void message.error(
                              'Failed to delete analysis request',
                            );
                          } finally {
                            loadingMessage();
                          }
                        },
                        icon: null,
                        title: 'Delete Analysis Request',
                        okText: 'Delete',
                        closable: true,
                        okButtonProps: {
                          size: 'small',
                          danger: true,
                        },
                        cancelButtonProps: {
                          size: 'small',
                        },
                        content:
                          'Are you sure you want to delete this analysis request? This will delete all data associated with this analysis request.',
                      });
                    },
                    danger: true,
                  },
                ],
              }}
              onClick={
                status === 'running' ? handleStopAnalysis : handleStartAnalysis
              }
              disabled={!teamIsActive}
            >
              {status === 'running' ? (
                <>
                  Stop Analysis <PauseCircleOutlined />
                </>
              ) : (
                <>
                  Start Analysis <PlayCircleOutlined />
                </>
              )}
            </Dropdown.Button>
          </FilterRowButtonGroupContainer>
        </FilterRowContainer>
        <Table
          loading={
            analysisRequestQuery.isLoading ||
            analysisRequestResultsQuery.isLoading
          }
          size="middle"
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          rowKey={(record) => record.linkId}
          scroll={{ x: 'max-content' }}
          locale={{
            emptyText:
              'No data selected. Please add data to analyze by clicking the "Add Data" button.',
          }}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    gap: '1rem',
                    flexDirection: 'column',
                  }}
                >
                  {record.facetValues.map((facetValue) => {
                    const facet = facets.find(
                      (facet) => facet.id === facetValue.analysisRequestFacetId,
                    );
                    if (!facet) {
                      return null;
                    }
                    return (
                      <div
                        key={facetValue.analysisRequestFacetId}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography.Text strong>{facet.name}:</Typography.Text>
                        <Typography.Text>{facetValue.value}</Typography.Text>
                      </div>
                    );
                  })}
                </div>
              );
            },
            rowExpandable: (record) => {
              return record.facetValues[0]?.reasoning !== null;
            },
          }}
          style={{
            flex: 1,
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography.Text type="secondary" style={{ fontSize: '0.8rem' }}>
            Analyze can make mistakes. Check important info.
          </Typography.Text>
        </div>
      </div>
    </>
  );
};

export default AnalysisRequestEditor;
