import FileOutlined from '@ant-design/icons/FileOutlined';
import FolderOutlined from '@ant-design/icons/FolderOutlined';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import Button from 'antd/es/button';
import Dropdown from 'antd/es/dropdown';
import Modal from 'antd/es/modal';
import Spin from 'antd/es/spin';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { styled } from 'styled-components';

import CreateDocumentForm from './forms/CreateDocumentForm';
import CreateProjectForm from './forms/CreateProjectForm';

import { useTeamIsActive } from '@utils/billing';
import { useTeamId } from '@utils/router';
import { screenSizes } from '@utils/theme';

const StyledButton = styled(Button)`
  @media (max-width: ${screenSizes.tablet}px) {
    min-width: 2rem;
    height: 2rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10rem;

    & > span:last-child {
      display: none;
    }
  }
`;

const QuickCreateButton = () => {
  const teamId = useTeamId();
  const [searchParams, setSearchParams] = useSearchParams();
  const teamIsActive = useTeamIsActive();

  const quickCreateType = searchParams.get('quickCreateType');

  const onCloseQuickCreate = () => {
    setSearchParams((prev) => {
      prev.delete('quickCreateType');
      return prev;
    });
  };

  const items = useMemo(() => {
    if (!teamId) return [];
    return [
      {
        key: 'document',
        label: 'Document',
        icon: <FileOutlined />,
        onClick: () => {
          setSearchParams((prev) => {
            prev.set('quickCreateType', 'document');
            return prev;
          });
        },
      },

      {
        key: 'project',
        label: 'Project',
        icon: <FolderOutlined />,
        onClick: () => {
          setSearchParams((prev) => {
            prev.set('quickCreateType', 'project');
            return prev;
          });
        },
      },
    ];
  }, [setSearchParams, teamId]);

  if (!teamId) return <Spin />;

  return (
    <>
      <Modal
        title="Create Document"
        open={quickCreateType === 'document'}
        onCancel={onCloseQuickCreate}
        footer={null}
        destroyOnClose
      >
        <CreateDocumentForm
          teamId={teamId}
          onFinish={onCloseQuickCreate}
          initialLinks={[{ id: teamId, type: 'team' }]}
        />
      </Modal>
      <Modal
        title="Create Project"
        open={quickCreateType === 'project'}
        onCancel={onCloseQuickCreate}
        footer={null}
        destroyOnClose
      >
        <CreateProjectForm teamId={teamId} onSuccess={onCloseQuickCreate} />
      </Modal>
      <Dropdown
        trigger={['click']}
        menu={{
          items,
        }}
      >
        <StyledButton
          disabled={!teamIsActive}
          icon={<PlusCircleOutlined />}
          type="primary"
        >
          Quick Create
        </StyledButton>
      </Dropdown>
    </>
  );
};

export default QuickCreateButton;
