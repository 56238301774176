import { ThemeContext } from '@/App';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import Button from 'antd/es/button';
import Popconfirm from 'antd/es/popconfirm';
import Typography from 'antd/es/typography';
import { type ReactNode, useContext } from 'react';

import { usePersistentState } from '@utils/hooks';
import { track } from '@utils/mixpanel';

export const Hero = ({
  id,
  color,
  imgSrc,
  title,
  extra,
}: {
  id: string;
  color: string | { light: string; dark: string };
  imgSrc: string;
  title: ReactNode;
  extra?: ReactNode;
}) => {
  const [isHeroMinimized, setIsHeroMinimized] = usePersistentState(
    `isHeroMinimizedV1-${id}`,
    false,
  );

  const themeContext = useContext(ThemeContext);
  const background =
    typeof color === 'string' ? color : color[themeContext.value];

  if (isHeroMinimized) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        background,
        borderRadius: '1rem',
        minHeight: '13rem',
        maxHeight: '15rem',
        paddingLeft: '2rem',
        marginBottom: '1rem',
        justifyContent: 'space-between',
        alignItems: 'center',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
          position: 'absolute',
          top: '1rem',
          right: '1rem',
        }}
      >
        <Popconfirm
          title="Are you sure you want to hide this?"
          onConfirm={() => {
            track('HERO_MINIMIZED', { heroId: id });
            setIsHeroMinimized(true);
          }}
          okText="Yes"
          cancelText="No"
          showArrow
          placement="left"
        >
          <Button
            type="text"
            size="small"
            shape="circle"
            icon={<CloseCircleOutlined />}
          />
        </Popconfirm>
      </div>
      <div style={{ flex: 1 }}>
        <Typography.Title
          level={4}
          style={{
            marginBottom: '0.5rem',
          }}
        >
          {title}
        </Typography.Title>
        {extra}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '100%',
          width: '40%',
          maxWidth: '500px',
        }}
      >
        <img
          src={imgSrc}
          alt="Business"
          style={{
            maxWidth: '100%',
            objectFit: 'contain',
            padding: '2rem',
          }}
        />
      </div>
    </div>
  );
};
