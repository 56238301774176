import Layout from 'antd/es/layout';
import theme from 'antd/es/theme';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ThemeContext } from '../App';
import HelpButton from './HelpButton';
import MenuButton from './MenuButton';
import QuickCreateButton from './QuickCreateButton';
import Settings from './Settings';
import SimpleSearch from './SimpleSearch';

import LogoDarkMode from '@assets/logo-dark.png';
import LogoLightMode from '@assets/logo-light.png';
import { useTeamId } from '@utils/router';
import { screenSizes } from '@utils/theme';

const StyledMenuItems = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: ${screenSizes.tablet}px) {
    width: 100%;
  }
  @media (max-width: ${screenSizes.mobile}px) {
    width: auto;
  }
`;

const Header = styled(Layout.Header)<{
  $backgroundColor: string;
  $borderRadius: number;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  background: ${(props) => props.$backgroundColor};
  border-radius: ${(props) => props.$borderRadius};
`;

const TopBar = () => {
  const teamId = useTeamId();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const themeContext = useContext(ThemeContext);

  return (
    <>
      <Header
        $backgroundColor={colorBgContainer}
        $borderRadius={borderRadiusLG}
      >
        <Link
          to="/"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={themeContext.value === 'dark' ? LogoDarkMode : LogoLightMode}
            style={{
              margin: '0 1rem',
              width: '50px',
            }}
          />
        </Link>
        <StyledMenuItems>
          {teamId ? <SimpleSearch teamId={teamId} /> : null}
          {teamId ? <QuickCreateButton /> : null}
          <HelpButton />
          <Settings />
          <MenuButton />
        </StyledMenuItems>
      </Header>
    </>
  );
};

export default TopBar;
