import { z } from 'zod';

import { createPaginationRequestQuerySchema } from './pagination';
import { commaDelimitedList } from './utils';

const InsightRequestResultTypeSchema = z.enum([
  'summary',
  'issues',
  'extracted_questions',
]);

export const InsightRequestRunStatusSchema = z.enum([
  'pending',
  'running',
  'completed',
  'failed',
  'cancelled',
]);

export const InsightAnalysisTypeSchema = z.enum(['coherence']);

export type InsightAnalysisType = z.infer<typeof InsightAnalysisTypeSchema>;

export const InsightRequestLinkSchema = z.object({
  type: z.enum(['document']),
  id: z.string().nanoid(),
});

export type InsightRequestLink = z.infer<typeof InsightRequestLinkSchema>;

export const InsightRequestSchema = z.object({
  id: z.string().nanoid(),
  teamId: z.string().nanoid(),
  analysisType: InsightAnalysisTypeSchema,
  latestRunStatus: InsightRequestRunStatusSchema.nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  links: z.array(InsightRequestLinkSchema),
});

export type InsightRequest = z.infer<typeof InsightRequestSchema>;

export const ListInsightRequestFiltersSchema = z
  .object({
    insightRequestIds: commaDelimitedList(
      z.string().nanoid().array(),
    ).optional(),
    teamIds: commaDelimitedList(z.string().nanoid().array()).optional(),
    analysisTypes: commaDelimitedList(z.enum(['coherence']).array()).optional(),
  })
  .merge(createPaginationRequestQuerySchema(z.enum(['createdAt'])));

export type ListInsightRequestFilters = z.infer<
  typeof ListInsightRequestFiltersSchema
>;

export const ListInsightRequestResponseSchema = z.array(InsightRequestSchema);

export type ListInsightRequestResponse = z.infer<
  typeof ListInsightRequestResponseSchema
>;

export const CreateInsightRequestSchema = InsightRequestSchema.pick({
  teamId: true,
  analysisType: true,
  links: true,
});

export type CreateInsightRequest = z.infer<typeof CreateInsightRequestSchema>;

export const UpdateInsightRequestSchema = z.object({});

export type UpdateInsightRequest = z.infer<typeof UpdateInsightRequestSchema>;

export const InsightRequestResultSummarySchema = z.object({
  summary: z.string(),
});

export type InsightRequestResultSummary = z.infer<
  typeof InsightRequestResultSummarySchema
>;

export const InsightRequestResultIssuesSchema = z.object({
  issues: z.array(
    z.object({
      issue: z.string(),
      description: z.string().optional(),
      examples: z.array(z.string()),
      consequences: z.string(),
    }),
  ),
  documentExtractionId: z.string().nanoid(),
});

export const InsightRequestResultExtractedQuestionsSchema = z.array(
  z.object({
    question: z.string(),
    answer: z.string(),
  }),
);

export type InsightRequestResultExtractedQuestions = z.infer<
  typeof InsightRequestResultExtractedQuestionsSchema
>;

export type InsightRequestResultIssues = z.infer<
  typeof InsightRequestResultIssuesSchema
>;

const InsightRequestResultBaseSchema = z.object({
  id: z.string().nanoid(),
  insightRequestId: z.string().nanoid(),
  insightRequestRunId: z.string().nanoid(),
  createdAt: z.date(),
  resultType: InsightRequestResultTypeSchema,
});

export const InsightRequestResultSchema = z.union([
  InsightRequestResultBaseSchema.extend({
    resultType: z.literal('summary'),
    resultData: InsightRequestResultSummarySchema,
  }),
  InsightRequestResultBaseSchema.extend({
    resultType: z.literal('issues'),
    resultData: InsightRequestResultIssuesSchema,
  }),
  InsightRequestResultBaseSchema.extend({
    resultType: z.literal('extracted_questions'),
    resultData: InsightRequestResultExtractedQuestionsSchema,
  }),
]);

export type InsightRequestResult = z.infer<typeof InsightRequestResultSchema>;

export const ListInsightRequestResultFiltersSchema = z
  .object({
    insightRequestIds: commaDelimitedList(
      z.string().nanoid().array(),
    ).optional(),
    insightRequestRunIds: commaDelimitedList(
      z.string().nanoid().array(),
    ).optional(),
    insightRequestResultIds: commaDelimitedList(
      z.string().nanoid().array(),
    ).optional(),
    resultTypes: commaDelimitedList(
      InsightRequestResultTypeSchema.array(),
    ).optional(),
  })
  .merge(createPaginationRequestQuerySchema(z.enum(['createdAt'])));

export type ListInsightRequestResultFilters = z.infer<
  typeof ListInsightRequestResultFiltersSchema
>;

export const ListInsightRequestResultResponseSchema = z.array(
  InsightRequestResultSchema,
);

export type ListInsightRequestResultResponse = z.infer<
  typeof ListInsightRequestResultResponseSchema
>;

export type InsightRequestRunStatus = z.infer<
  typeof InsightRequestRunStatusSchema
>;

export const InsightRequestRunSchema = z.object({
  id: z.string().nanoid(),
  insightRequestId: z.string().nanoid(),
  status: InsightRequestRunStatusSchema,
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export type InsightRequestRun = z.infer<typeof InsightRequestRunSchema>;

export const ListInsightRequestRunFiltersSchema = z
  .object({
    insightRequestRunIds: commaDelimitedList(
      z.string().nanoid().array(),
    ).optional(),
    insightRequestIds: commaDelimitedList(
      z.string().nanoid().array(),
    ).optional(),
    statuses: commaDelimitedList(
      InsightRequestRunStatusSchema.array(),
    ).optional(),
  })
  .merge(createPaginationRequestQuerySchema(z.enum(['createdAt'])));

export type ListInsightRequestRunFilters = z.infer<
  typeof ListInsightRequestRunFiltersSchema
>;

export const ListInsightRequestRunResponseSchema = z.array(
  InsightRequestRunSchema,
);

export type ListInsightRequestRunResponse = z.infer<
  typeof ListInsightRequestRunResponseSchema
>;

export const UpdateInsightRequestRunSchema = z.object({
  shouldCancel: z.boolean().optional(),
});

export type UpdateInsightRequestRun = z.infer<
  typeof UpdateInsightRequestRunSchema
>;

export const CreateInsightRequestRunSchema = z.object({
  insightRequestId: z.string().nanoid(),
});

export type CreateInsightRequestRun = z.infer<
  typeof CreateInsightRequestRunSchema
>;
