import App from 'antd/es/app';
import Form from 'antd/es/form';
import Select from 'antd/es/select';
import { useNavigate } from 'react-router-dom';

import { type CreateInsightRequest, type InsightRequest } from '@mai/types';

import ContentContainer from '@components/ContentContainer';
import FormContainer from '@components/FormContainer';
import FormSubmitButton from '@components/FormSubmitButton';
import { useFetchDocumentsQuery } from '@queries/documents';
import { apiClient } from '@queries/index';
import { useTeamId } from '@utils/router';

type NewInsightFormData = {
  documentId: string;
};

const InsightsNewPage = () => {
  const { message } = App.useApp();
  const teamId = useTeamId();
  const [form] = Form.useForm<NewInsightFormData>();
  const navigate = useNavigate();

  const documentsQuery = useFetchDocumentsQuery(
    { teamIds: teamId ? [teamId] : undefined },
    !!teamId,
  );
  const documents = documentsQuery.data?.data.map((document) => ({
    label: document.title,
    value: document.id,
  }));

  if (!teamId) {
    return <ContentContainer.Loading />;
  }

  const onFinish = async (values: Partial<NewInsightFormData> | null) => {
    if (!values?.documentId) {
      void message.error('Please select a document');
      return;
    }

    const payload = {
      teamId,
      links: [{ type: 'document' as const, id: values.documentId }],
      analysisType: 'coherence',
    } satisfies CreateInsightRequest;

    const loadingMessage = message.loading('Creating insight...');
    try {
      const { data } = await apiClient.post<InsightRequest>(
        '/insight-requests',
        payload,
      );
      loadingMessage();
      void message.success('Insight created');
      navigate(`/team/${teamId}/insights/${data.id}`);
    } catch (error) {
      loadingMessage();
      void message.error('Failed to create insight');
    }
  };

  return (
    <ContentContainer>
      <ContentContainer.Header
        title="Insights"
        breadcrumbs={[
          {
            label: 'Team',
            to: `/team/${teamId}`,
          },
          {
            label: 'Insights',
            to: `/team/${teamId}/insights`,
          },
          {
            label: 'New',
          },
        ]}
      />
      <FormContainer>
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item
            name="documentId"
            label="Document"
            rules={[{ required: true }]}
          >
            <Select options={documents} />
          </Form.Item>
          <FormSubmitButton text="Next" />
        </Form>
      </FormContainer>
    </ContentContainer>
  );
};

export default InsightsNewPage;
