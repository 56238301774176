import { useNavigate } from 'react-router-dom';

import ContentContainer from '@components/ContentContainer';
import FormContainer from '@components/FormContainer';
import CreateDocumentForm from '@components/forms/CreateDocumentForm';
import { useTeamId } from '@utils/router';

const CreateDocumentPage = () => {
  const teamId = useTeamId();
  const navigate = useNavigate();

  if (!teamId) {
    return <ContentContainer.Error />;
  }

  return (
    <ContentContainer>
      <ContentContainer.Header
        title="Create Document"
        breadcrumbs={[
          { label: 'Home', to: `/team/${teamId}` },
          { label: 'Documents', to: `/team/${teamId}/documents` },
          { label: 'Create' },
        ]}
      />
      <FormContainer>
        <CreateDocumentForm
          teamId={teamId}
          onFinish={() => navigate('..')}
          initialLinks={[{ id: teamId, type: 'team' }]}
        />
      </FormContainer>
    </ContentContainer>
  );
};

export default CreateDocumentPage;
