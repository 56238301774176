import { useContext } from 'react';

import { ThemeContext } from '../App';

import LogoDarkMode from '@assets/logo-dark.png';
import LogoLightMode from '@assets/logo-light.png';

export const Logo = ({ width }: { width: string }) => {
  const themeContext = useContext(ThemeContext);
  return (
    <img
      src={themeContext.value === 'dark' ? LogoDarkMode : LogoLightMode}
      width={width}
    />
  );
};
