import Tabs from 'antd/es/tabs';

import ContentContainer from '@components/ContentContainer';
import UsersTable from '@components/tables/UsersTable';

const AdminPage = () => {
  return (
    <ContentContainer>
      <ContentContainer.Header title="Admin Console" />
      <Tabs
        items={[
          {
            key: 'users',
            label: 'Users',
            children: (
              <ContentContainer>
                <ContentContainer.Header title="Users" />
                <UsersTable />
              </ContentContainer>
            ),
          },
        ]}
      />
    </ContentContainer>
  );
};

export default AdminPage;
