import axios from 'axios';

export const API_BASE_URL =
  import.meta.env.MODE === 'development'
    ? 'http://localhost:3001'
    : 'https://api.moderately.ai';

export const apiClient = axios.create({
  ...axios.defaults,
  baseURL: API_BASE_URL,
  timeout: 10_000,
  withCredentials: true,
  paramsSerializer: (params) => {
    // Convert arrays to comma-separated strings
    return Object.entries(params)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          const filteredValues = value
            .filter((v) => v !== undefined)
            .map(encodeURIComponent);
          return `${key}=${filteredValues.join(',')}`;
        } else if (value instanceof Date) {
          return `${key}=${value.toISOString()}`;
        } else if (value === undefined) {
          return '';
        }
        return `${key}=${value}`;
      })
      .join('&');
  },
});

apiClient.interceptors.request.use(
  function (config) {
    const embedSessionToken = localStorage.getItem('embedSessionToken');
    if (embedSessionToken) {
      config.headers.Authorization = `Bearer ${embedSessionToken}`;
    } else {
      const sessionToken = localStorage.getItem('sessionToken');
      if (sessionToken) {
        config.headers.Authorization = `Bearer ${sessionToken}`;
      }
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

export type QueryOptions = {
  enabled?: boolean;
  retry?: boolean;
  keepPreviousData?: boolean;
};
