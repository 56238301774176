import Button from 'antd/es/button';
import Modal from 'antd/es/modal';
import { type ColumnType } from 'antd/es/table';
import { useEffect, useState } from 'react';

import { type DocumentExtractionType } from '@mai/types';

import Table from '../Table';

import StackedDateTime from '@components/StackedDateTime';
import { useFetchDocumentExtractionsQuery } from '@queries/documents';
import { renderDate } from '@utils/dates';

type DocumentExtractionRow = {
  id: string;
  type: DocumentExtractionType;
  viewUrl: string;
};

const DocumentExtractionsTable = ({
  documentId,
  size,
}: {
  teamId: string;
  documentId: string;
  size?: 'small' | 'middle' | 'large';
}) => {
  const [currentViewUrl, setCurrentViewUrl] = useState<string | null>(null);
  const [currentViewContent, setCurrentViewContent] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (currentViewUrl !== null) {
      void fetch(currentViewUrl)
        .then((response) => response.text())
        .then((text) => {
          setCurrentViewContent(text);
        });
    }
  }, [currentViewUrl]);

  const documentExtractionsQuery = useFetchDocumentExtractionsQuery({
    documentIds: [documentId],
  });

  const columns: ColumnType<DocumentExtractionRow>[] = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 300,
      render: (type) => {
        if (type === 'ocr') {
          return 'Document Text Extraction';
        } else if (type === 'post_processing') {
          return 'Post Processing';
        }
        return null;
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => {
        if (size !== 'small') {
          return <StackedDateTime dateString={createdAt} />;
        }
        return renderDate(createdAt);
      },
    },
    {
      key: 'view',
      align: 'right',
      width: 80,
      render: (_, row) => {
        return (
          <Button
            size="small"
            onClick={() => {
              setCurrentViewUrl(row.viewUrl);
            }}
          >
            View
          </Button>
        );
      },
    },
  ];

  const dataSource =
    documentExtractionsQuery.data?.map((documentExtraction) => ({
      id: documentExtraction.id,
      type: documentExtraction.type,
      viewUrl: documentExtraction.viewUrl,
      createdAt: documentExtraction.createdAt,
    })) ?? [];

  return (
    <>
      <Modal
        open={currentViewUrl !== null}
        onClose={() => setCurrentViewUrl(null)}
        title="View Extracted Text"
        onCancel={() => setCurrentViewUrl(null)}
        footer={null}
        width={800}
      >
        {currentViewContent}
      </Modal>
      <Table<DocumentExtractionRow>
        columns={columns}
        loading={documentExtractionsQuery.isLoading}
        dataSource={dataSource}
        rowKey="id"
        size={size}
        pagination={false}
      />
    </>
  );
};

export default DocumentExtractionsTable;
