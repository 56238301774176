import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import InboxOutlined from '@ant-design/icons/InboxOutlined';
import { useQueryClient } from '@tanstack/react-query';
import App from 'antd/es/app';
import Button from 'antd/es/button';
import Descriptions from 'antd/es/descriptions';
import List from 'antd/es/list';
import Select from 'antd/es/select';
import Spin from 'antd/es/spin';
import Typography from 'antd/es/typography';
import Dragger from 'antd/es/upload/Dragger';
import type { RcFile } from 'antd/es/upload/interface';
import uniqBy from 'lodash.uniqby';
import { type ReactNode, useState } from 'react';

import { type CreateDocumentRequest } from '@mai/types';

import { createDocument } from '@queries/documents';
import { useProjectsQuery } from '@queries/projects';
import { useSessionUserId } from '@utils/auth';
import { logger } from '@utils/logger';
import { track } from '@utils/mixpanel';

const CreateDocumentForm = ({
  teamId,
  projectId: initialProjectId,
  onFinish,
  initialLinks,
}: {
  teamId: string;
  projectId?: string | null;
  onFinish?: () => void;
  initialLinks: CreateDocumentRequest['links'];
}) => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [files, setFiles] = useState<RcFile[]>([]);
  const [currentFile, setCurrentFile] = useState<string | null>(null);
  const sessionUserId = useSessionUserId();
  const failedFiles: string[] = [];
  const finishedFiles: string[] = [];
  const [projectId, setProjectId] = useState<string | null>(
    initialProjectId ?? null,
  );

  const projectsQuery = useProjectsQuery({
    teamIds: [teamId],
  });
  const projects = projectsQuery.data ?? [];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        gap: '1rem',
      }}
    >
      {projects.length ? (
        <Select
          allowClear
          options={projects.map((project) => ({
            label: project.name,
            value: project.id,
          }))}
          onChange={(value) => {
            setProjectId(value);
          }}
          style={{
            width: '100%',
          }}
          placeholder="Optional: Add to Project"
          value={projectId}
        />
      ) : null}
      <div
        style={{
          width: '100%',
        }}
      >
        <Dragger
          accept={'.pdf, .docx'}
          multiple={true}
          beforeUpload={(_, fileList) => {
            // set files and reset state
            setFiles(fileList);
            setIsDone(false);
            setIsSubmitting(false);
            return false;
          }}
          showUploadList={false}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click here to select files, or drag and drop your files here.
          </p>
        </Dragger>
      </div>
      <div
        style={{
          width: '100%',
          overflow: 'auto',
          maxHeight: '300px',
        }}
      >
        {files.length ? (
          <List
            dataSource={files.sort((a, b) => {
              return a.name.localeCompare(b.name);
            })}
            renderItem={(file) => {
              let decoration: ReactNode = (
                <Button
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    setFiles((prev) => prev.filter((f) => f.uid !== file.uid));
                  }}
                />
              );
              if (finishedFiles.includes(file.uid)) {
                decoration = <CheckCircleOutlined />;
              } else if (currentFile === file.uid) {
                decoration = (
                  <Spin
                    size="small"
                    style={{
                      marginRight: '0.25rem',
                    }}
                  />
                );
              } else if (failedFiles.includes(file.uid)) {
                decoration = <CloseCircleOutlined />;
              }
              return (
                <List.Item>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      height: '20px',
                    }}
                  >
                    <Typography.Text
                      ellipsis
                      style={{
                        maxWidth: '90%',
                      }}
                    >
                      {file.name}
                    </Typography.Text>
                    {decoration}
                  </div>
                </List.Item>
              );
            }}
          />
        ) : null}
      </div>
      <Descriptions column={1}>
        <Descriptions.Item label="Files">
          {files.length} files selected
        </Descriptions.Item>
        <Descriptions.Item label="Total Size">
          {files.length ? (
            <>
              {'~'}
              {Math.ceil(
                files.reduce((acc, file) => acc + (file.size ?? 0), 0) /
                  1_000_000,
              )}
              {' MB'}
            </>
          ) : (
            '0 MB'
          )}
        </Descriptions.Item>
      </Descriptions>
      <Button
        type="primary"
        loading={isSubmitting}
        disabled={isDone || !files.length}
        onClick={async () => {
          setIsSubmitting(true);
          try {
            for (const file of files) {
              setCurrentFile(file.uid);
              try {
                const title = file.name;
                const description = null;
                const documentId = await createDocument({
                  title,
                  description,
                  file,
                  links: projectId
                    ? uniqBy(
                        [...initialLinks, { id: projectId, type: 'project' }],
                        ({ type, id }) => `${type}-${id}`,
                      )
                    : initialLinks,
                });
                logger.info(
                  {
                    documentId,
                    teamId,
                  },
                  'Document created',
                );
                finishedFiles.push(file.uid);
                void queryClient.invalidateQueries({
                  queryKey: ['documents'],
                });
              } catch (e) {
                logger.warn(
                  {
                    error: e,
                    file,
                  },
                  'Failed to create document',
                );
                failedFiles.push(file.uid);
              }
            }
          } finally {
            setIsSubmitting(false);
            setIsDone(true);
            const errorCount = failedFiles.length;
            track('CREATED_DOCUMENT', {
              teamId,
              sessionUserId,
              metadata: {
                fileCount: files.length,
                errorCount,
              },
            });
            if (errorCount === files.length) {
              void message.error(
                `Failed to upload ${errorCount} file${errorCount > 1 ? 's' : ''}`,
              );
            } else if (errorCount) {
              void message.warning(
                `Finished uploading ${errorCount} file${errorCount > 1 ? 's' : ''}`,
              );
            } else {
              void message.success(
                `Finished uploading ${files.length} file${files.length > 1 ? 's' : ''}`,
              );
              onFinish?.();
            }
          }
        }}
        style={{
          width: '100%',
        }}
      >
        Upload
      </Button>
    </div>
  );
};

export default CreateDocumentForm;
