import { queryClient } from '@/globals';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import DislikeFilled from '@ant-design/icons/DislikeFilled';
import DislikeOutlined from '@ant-design/icons/DislikeOutlined';
import LikeFilled from '@ant-design/icons/LikeFilled';
import LikeOutlined from '@ant-design/icons/LikeOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import SendOutlined from '@ant-design/icons/SendOutlined';
import App from 'antd/es/app';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Input from 'antd/es/input';
import Popconfirm from 'antd/es/popconfirm';
import Select from 'antd/es/select';
import Skeleton from 'antd/es/skeleton';
import Spin from 'antd/es/spin';
import theme from 'antd/es/theme';
import Tooltip from 'antd/es/tooltip';
import Typography from 'antd/es/typography';
import { type AxiosError } from 'axios';
import groupBy from 'lodash.groupby';
import uniqBy from 'lodash.uniqby';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  type ConversationMessage,
  type ConversationMessageLink,
  type CreateConversationMessageBody,
  type UpdateConversationBody,
} from '@mai/types';

import EmptyChatImage from '@assets/undraw_messaging.svg';
import ContentContainer from '@components/ContentContainer';
import {
  useFetchConversation,
  useFetchConversationMessages,
} from '@queries/conversations';
import { useFetchDocumentsQuery } from '@queries/documents';
import { apiClient } from '@queries/index';
import { useProjectsQuery } from '@queries/projects';
import { useFetchUserQuery } from '@queries/users';
import { subscribe, unsubscribe } from '@queries/websockets';
import { useSessionUserId } from '@utils/auth';
import { useTeamIsActive } from '@utils/billing';
import { useFocus } from '@utils/hooks';
import { logger } from '@utils/logger';
import { track } from '@utils/mixpanel';

const MarkdownContainer = styled.div`
  .markdown p {
    margin: 0.25rem 0;
  }
`;

const MessageContent = ({
  content,
  references,
  teamId,
}: {
  content: string;
  teamId: string;
  references: ConversationMessage['references'];
}) => {
  const referencesByDocumentId = useMemo(() => {
    return Object.entries(
      groupBy(references, (reference) => reference.documentId),
    );
  }, [references]);

  const documents = useFetchDocumentsQuery(
    {
      documentIds: uniqBy(
        referencesByDocumentId.map(([documentId]) => documentId),
        (documentId) => documentId,
      ),
    },
    referencesByDocumentId.length > 0,
  );

  return (
    <div>
      <MarkdownContainer>
        <ReactMarkdown className={'markdown'}>{content}</ReactMarkdown>
      </MarkdownContainer>
      {referencesByDocumentId.length > 0 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '0.25rem',
            flexWrap: 'wrap',
          }}
        >
          {referencesByDocumentId.map(([documentId, references], index) => (
            <Tooltip
              key={documentId}
              title={
                <div
                  style={{
                    height: '200px',
                    maxWidth: '300px',
                    overflow: 'auto',
                    textOverflow: 'ellipsis',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <b style={{ marginBottom: '0.5rem' }}>
                    {
                      documents.data?.data?.find(
                        (document) => document.id === documentId,
                      )?.title
                    }
                  </b>
                  {references.map((reference, index) => (
                    <div key={reference.content}>
                      {reference.content}
                      {index < references.length - 1 && (
                        <div
                          style={{
                            borderBottom: '1px solid #e8e8e8',
                            width: '100%',
                            margin: '1rem 0',
                          }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              }
            >
              <Link to={`/team/${teamId}/documents/${documentId}`}>
                {`(${index + 1})`}
              </Link>
            </Tooltip>
          ))}
        </div>
      )}
    </div>
  );
};

const ChatInput = ({
  disabled,
  onSubmit,
  links,
}: {
  disabled: boolean;
  onSubmit: ({
    value,
  }: {
    value: string;
    links: ConversationMessageLink[];
  }) => Promise<void>;
  links: ConversationMessageLink[];
}) => {
  const [value, setValue] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputRef, setInputFocus] = useFocus();
  const { message } = App.useApp();

  const onClickSend = () => {
    setIsSubmitting(true);
    onSubmit({ value, links })
      .then(() => {
        setValue('');
      })
      .catch((e: AxiosError<{ error: string }>) => {
        const error = e.response?.data?.error;
        void message.error(
          `Error: ${error ?? 'Failed to generate message. Please try again later'}`,
        );
      })
      .finally(() => {
        setIsSubmitting(false);
        setInputFocus();
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        width: '100%',
        maxWidth: '600px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '0.5rem',
          alignItems: 'center',
        }}
      >
        <Input.TextArea
          ref={inputRef}
          autoFocus
          tabIndex={0}
          autoSize={{ minRows: 1, maxRows: 3 }}
          rows={1}
          placeholder="Type your message here..."
          size="large"
          disabled={disabled}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onPressEnter={(e) => {
            if (value.length > 0 && !e.shiftKey) {
              onClickSend();
              e.preventDefault(); // Prevent default behavior to avoid any additional handling by the browser
            }
          }}
        />
        <Button
          type="primary"
          size="large"
          shape="circle"
          loading={isSubmitting}
          disabled={!value.length || disabled}
          onClick={() => {
            if (value.length > 0) {
              onClickSend();
            }
          }}
        >
          {isSubmitting ? null : <SendOutlined />}
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography.Text type="secondary" style={{ fontSize: '0.7rem' }}>
          The assistant can make mistakes. Check important info.
        </Typography.Text>
      </div>
    </div>
  );
};

type MessageRowType = {
  id: string;
  role: 'user' | 'assistant' | 'system';
  content: string;
};

const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 1rem;
  }
`;

const ConversationHeader = ({
  teamId,
}: {
  conversationId: string;
  teamId: string;
  projectId?: string;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const focusedDocumentIds = useMemo(() => {
    return searchParams.get('focusedDocumentIds')?.split(',') || [];
  }, [searchParams]);

  const availableDocumentsQuery = useFetchDocumentsQuery({
    teamIds: teamId ? [teamId] : undefined,
  });

  const selectedDocumentsQuery = useFetchDocumentsQuery(
    {
      documentIds: focusedDocumentIds,
    },
    focusedDocumentIds.length > 0,
  );

  const documents = useMemo(() => {
    return uniqBy(
      [
        ...(availableDocumentsQuery.data?.data ?? []),
        ...(selectedDocumentsQuery.data?.data ?? []),
      ],
      (document) => document.id,
    ).sort((a, b) => {
      // ensure the focused documents appear first
      if (
        focusedDocumentIds.includes(a.id) &&
        !focusedDocumentIds.includes(b.id)
      ) {
        return -1;
      }
      if (
        focusedDocumentIds.includes(b.id) &&
        !focusedDocumentIds.includes(a.id)
      ) {
        return 1;
      }
      return a.createdAt.getTime() - b.createdAt.getTime();
    });
  }, [
    availableDocumentsQuery.data?.data,
    focusedDocumentIds,
    selectedDocumentsQuery.data?.data,
  ]);

  const projectsQuery = useProjectsQuery(
    {
      teamIds: [teamId],
    },
    !!teamId,
  );

  const projects = useMemo(() => {
    return projectsQuery.data ?? [];
  }, [projectsQuery.data]);

  const focusedResourceType =
    searchParams.get('focusedResourceType') ?? 'document';

  const focusedProjectId = searchParams.get('focusedProjectId');

  const resourceSelect = useMemo(() => {
    if (focusedResourceType === 'document') {
      const focusedDocumentIds =
        searchParams.get('focusedDocumentIds')?.split(',') ?? [];
      return (
        <Select
          allowClear
          value={focusedDocumentIds}
          mode="multiple"
          maxTagCount={1}
          onChange={(value) => {
            setSearchParams((prev) => {
              if (value.length === 0) {
                prev.delete('focusedDocumentIds');
              } else {
                prev.set('focusedDocumentIds', value.join(','));
              }
              return prev;
            });
          }}
          placeholder="Select documents"
          style={{ width: '100%' }}
          options={documents.map((document) => ({
            value: document.id,
            label: document.title,
          }))}
        />
      );
    }
    return (
      <Select
        value={focusedProjectId}
        allowClear
        onChange={(value) => {
          setSearchParams((prev) => {
            if (value) {
              prev.set('focusedProjectId', value);
            } else {
              prev.delete('focusedProjectId');
            }
            return prev;
          });
        }}
        placeholder="Select a project"
        style={{ width: '100%' }}
        options={projects.map((project) => ({
          value: project.id,
          label: project.name,
        }))}
      />
    );
  }, [
    focusedResourceType,
    focusedProjectId,
    projects,
    searchParams,
    documents,
    setSearchParams,
  ]);

  return (
    <div
      style={{
        display: 'flex',
        gap: '0.5rem',
        alignItems: 'center',
        marginBottom: '1rem',
        justifyContent: 'space-between',
      }}
    >
      <Select
        value={focusedResourceType}
        onChange={(value) => {
          setSearchParams({ focusedResourceType: value });
        }}
        placeholder="Select a resource type"
        style={{ width: '110px' }}
      >
        <Select.Option value="document">Document</Select.Option>
        <Select.Option value="project">Project</Select.Option>
      </Select>
      {resourceSelect}
    </div>
  );
};

const Message = ({
  id,
  role,
  content,
  references,
  onDeleteMessage,
  isRegenerating,
  onRegenerate,
  teamId,
  userFullName,
}: MessageRowType & {
  references: ConversationMessage['references'];
  onDeleteMessage: () => Promise<void>;
  isRegenerating?: boolean;
  onRegenerate?: () => Promise<void>;
  teamId: string;
  userFullName?: string;
}) => {
  const {
    token: { colorPrimary },
  } = theme.useToken();
  const { message } = App.useApp();
  const sessionUserId = useSessionUserId();
  const fetchUser = useFetchUserQuery(sessionUserId);
  const [isDeleting, setIsDeleting] = useState(false);
  const [feedback, setFeedback] = useState<'like' | 'dislike' | null>(null);

  const name = userFullName ?? fetchUser.data?.name ?? (
    <Skeleton active paragraph={false} />
  );
  return (
    <div>
      <StyledCard>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography.Text type="secondary" strong>
            {role === 'assistant' ? 'Assistant' : name}
          </Typography.Text>
          <div
            style={{
              display: 'flex',
              gap: '0.5rem',
            }}
          >
            <div>
              {role === 'assistant' && (
                <>
                  <Button
                    size="small"
                    shape="circle"
                    type="text"
                    loading={isDeleting}
                    icon={
                      feedback === 'like' ? <LikeFilled /> : <LikeOutlined />
                    }
                    style={{
                      color: feedback === 'like' ? colorPrimary : undefined,
                    }}
                    onClick={() => {
                      setFeedback('like');
                      track('LIKED_ASSISTANT_MESSAGE', {
                        conversationMessageId: id,
                      });
                    }}
                  />
                  <Button
                    size="small"
                    shape="circle"
                    type="text"
                    loading={isDeleting}
                    icon={
                      feedback === 'dislike' ? (
                        <DislikeFilled />
                      ) : (
                        <DislikeOutlined />
                      )
                    }
                    style={{
                      color: feedback === 'dislike' ? colorPrimary : undefined,
                    }}
                    onClick={() => {
                      setFeedback('dislike');
                      track('DISLIKED_ASSISTANT_MESSAGE', {
                        conversationMessageId: id,
                      });
                    }}
                  />
                </>
              )}
            </div>
            {onRegenerate && (
              <Button
                size="small"
                shape="circle"
                type="text"
                loading={isRegenerating}
                icon={<ReloadOutlined />}
                onClick={onRegenerate}
              />
            )}
            <Popconfirm
              title="Delete this message?"
              onConfirm={async () => {
                setIsDeleting(true);
                try {
                  await onDeleteMessage();
                } catch (e) {
                  await message.error(
                    'Failed to delete message. Please try again.',
                  );
                } finally {
                  setIsDeleting(false);
                }
              }}
              icon={<DeleteOutlined />}
              okText="Yes"
              cancelText="No"
            >
              <Button
                size="small"
                shape="circle"
                type="text"
                loading={isDeleting}
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </div>
        </div>
        <MessageContent
          content={content}
          teamId={teamId}
          references={references}
        />
      </StyledCard>
    </div>
  );
};

const Conversation = ({
  conversationId,
  teamId,
  userFullName,
}: {
  conversationId: string;
  teamId: string;
  userFullName?: string;
  projectId?: string;
}) => {
  const sessionUserId = useSessionUserId();
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasSetDefaultParams, setHasSetDefaultParams] = useState(false);

  const { message } = App.useApp();

  const teamIsActive = useTeamIsActive();

  const [isSending, setIsSending] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  const [currentStreamingMessageId, setCurrentStreamingMessageId] = useState<
    string | null
  >(null);
  const [currentStreamingMessageContent, setCurrentStreamingMessageContent] =
    useState<string | null>(null);
  const [
    currentStreamingMessageInformation,
    setCurrentStreamingMessageInformation,
  ] = useState<string | null>(null);

  const autoScrollRef = useRef<HTMLDivElement>(null);

  const conversationQuery = useFetchConversation(conversationId);
  const conversation = conversationQuery.data;

  const conversationMessagesQuery =
    useFetchConversationMessages(conversationId);

  const messages = useMemo(() => {
    return (
      conversationMessagesQuery.data?.sort(
        (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
      ) ?? []
    );
  }, [conversationMessagesQuery.data]);

  const streamingMessage = useMemo(() => {
    if (!currentStreamingMessageId || !currentStreamingMessageContent) return;
    return {
      id: currentStreamingMessageId,
      role: 'assistant' as const,
      content: currentStreamingMessageContent,
      references: [],
    };
  }, [currentStreamingMessageContent, currentStreamingMessageId]);

  // Clear the current streaming message content and id if it appears in the list of messages
  useEffect(() => {
    if (!streamingMessage) return;
    if (messages.some((message) => message.id === streamingMessage.id)) {
      setIsGenerating(false);
      setCurrentStreamingMessageId(null);
      setCurrentStreamingMessageContent(null);
      setCurrentStreamingMessageInformation(null);
    }
  }, [messages, streamingMessage]);

  // Subscribe to updates on the conversation itself
  useEffect(() => {
    if (!conversationId) return;
    subscribe('conversation_updated', { conversationId }, () => {
      void queryClient.invalidateQueries({
        queryKey: ['conversation'],
      });
      void queryClient.invalidateQueries({
        queryKey: ['conversationMessages'],
      });
    });
    subscribe('chat_completion_chunk', { conversationId }, (data) => {
      if (currentStreamingMessageId !== data.conversationMessageId)
        setCurrentStreamingMessageId(data.conversationMessageId);
      if (data.contentType === 'chunk') {
        setCurrentStreamingMessageContent(data.content);
      } else if (data.contentType === 'information') {
        setCurrentStreamingMessageInformation(data.content);
      }
    });
    return () => {
      unsubscribe('conversation_updated', { conversationId });
      unsubscribe('chat_completion_chunk', { conversationId });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Auto scroll to the bottom of the chat when a new message is received
  // TODO: if a user has scrolled up manually, we probably shouldn't pull them to the bottom
  useEffect(() => {
    if (!autoScrollRef.current) return;
    autoScrollRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [messages, streamingMessage]);

  // Source the currently selected links from the search params
  const links: ConversationMessageLink[] = useMemo(() => {
    const focusedResourceType =
      searchParams.get('focusedResourceType') ?? 'document';

    if (focusedResourceType === 'document') {
      const focusedDocumentIds =
        searchParams.get('focusedDocumentIds')?.split(',') ?? [];
      return focusedDocumentIds.map((id) => ({
        type: 'document',
        id,
      }));
    } else if (focusedResourceType === 'project') {
      const focusedProjectId = searchParams.get('focusedProjectId');
      return focusedProjectId
        ? [{ type: 'project', id: focusedProjectId }]
        : [];
    }
    return [];
  }, [searchParams]);

  // On first load, set the filters to the latest message's links
  useEffect(() => {
    if (conversationMessagesQuery.isLoading || hasSetDefaultParams) {
      return;
    }
    const latestUserMessage = conversationMessagesQuery.data
      ?.filter((message) => message.role === 'user')
      .at(-1);
    if (!latestUserMessage) {
      return;
    }
    const projectId = latestUserMessage.links.find(
      (link) => link.type === 'project',
    )?.id;
    const documentIds = latestUserMessage.links
      .filter((link) => link.type === 'document')
      .map(({ id }) => id);
    if (projectId) {
      setSearchParams((prev) => {
        prev.set('focusedResourceType', 'project');
        prev.set('focusedProjectId', projectId);
        return prev;
      });
    } else if (documentIds.length > 0) {
      setSearchParams((prev) => {
        prev.set('focusedResourceType', 'document');
        prev.set('focusedDocumentIds', documentIds.join(','));
        return prev;
      });
    }
    setHasSetDefaultParams(true);
  }, [
    conversationMessagesQuery.data,
    conversationMessagesQuery.isLoading,
    hasSetDefaultParams,
    setSearchParams,
  ]);

  // Broken state, shouldn't happen
  if (!teamId || !conversationId) {
    logger.error(
      {
        teamId,
        conversationId,
      },
      'Missing teamId or conversationId in the path',
    );
    return <ContentContainer.Error />;
  }

  const onMessageSubmit = async ({
    value,
    links,
  }: {
    value: string;
    links: ConversationMessageLink[];
  }) => {
    setIsSending(true);
    setIsGenerating(true);
    try {
      await apiClient.post(`/conversations/${conversationId}/messages`, {
        content: value,
        role: 'user',
        links,
      } satisfies CreateConversationMessageBody);
      track('CREATED_CONVERSATION_MESSAGE', {
        conversationId,
        sessionUserId,
        teamId,
      });
      await conversationMessagesQuery.refetch();
    } finally {
      setIsSending(false);
    }
  };

  const renderedMessages = (
    streamingMessage ? [...messages, streamingMessage] : messages
  ).map((conversationMessage, i) => (
    <Message
      id={conversationMessage.id}
      teamId={teamId}
      userFullName={userFullName}
      key={conversationMessage.id}
      role={conversationMessage.role}
      content={conversationMessage.content}
      references={conversationMessage?.references ?? []}
      onDeleteMessage={async () => {
        await apiClient.delete(
          `/conversations/${conversationId}/messages/${conversationMessage.id}`,
        );
        track('DELETED_CONVERSATION_MESSAGE', {
          conversationId,
          conversationMessageId: conversationMessage.id,
          sessionUserId,
          teamId,
        });
        await conversationMessagesQuery.refetch();
      }}
      isRegenerating={isGenerating}
      onRegenerate={
        i === messages.length - 1 && conversationMessage.role === 'user'
          ? async () => {
              try {
                await apiClient.patch(`/conversations/${conversationId}`, {
                  shouldRegenerateMessages: true,
                } satisfies UpdateConversationBody);
                track('REGENERATED_CONVERSATION_MESSAGE', {
                  conversationId,
                  conversationMessageId: conversationMessage.id,
                  sessionUserId,
                  teamId,
                });
                setIsGenerating(true);
              } catch (e) {
                await message.error(
                  'Failed to regenerate message. Please try again.',
                );
              }
            }
          : undefined
      }
    />
  ));

  if (conversationQuery.isLoading || conversationMessagesQuery.isLoading) {
    return <ContentContainer.Loading />;
  }

  if (!conversation) {
    return <ContentContainer.NotFound />;
  }

  return (
    <>
      <ConversationHeader conversationId={conversationId} teamId={teamId} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            flexShrink: 1,
            overflowY: 'auto',
            height: '100%',
          }}
        >
          {renderedMessages}
          {messages.length === 0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
              }}
            >
              <img
                src={EmptyChatImage}
                alt="Empty chat"
                style={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: '300px',
                  marginBottom: '1rem',
                }}
              />
              <Typography.Text type="secondary">
                No messages in this chat yet
              </Typography.Text>
            </div>
          )}
          {isGenerating && !currentStreamingMessageContent && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '0.25rem',
              }}
            >
              <Typography.Text type="secondary">
                {currentStreamingMessageInformation ??
                  'Generating response ...'}
              </Typography.Text>
              <Spin style={{ marginRight: '0.25rem' }} />
            </div>
          )}

          <div ref={autoScrollRef} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: '1rem',
            flexShrink: 0,
            marginBottom: '1rem',
          }}
        >
          <ChatInput
            disabled={isSending || !teamIsActive}
            onSubmit={onMessageSubmit}
            links={links}
          />
        </div>
      </div>
    </>
  );
};

export default Conversation;
