import { z } from 'zod';

import { createPaginationRequestQuerySchema } from './pagination';
import { commaDelimitedList } from './utils';

export const DocumentSummarySchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string().nullable(),
  fileType: z.string(),
  fileName: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  archivedAt: z.coerce.date().nullable(),
  archivedBy: z.string().nullable(),
  hasProcessed: z.boolean(),
});

export type DocumentSummary = z.infer<typeof DocumentSummarySchema>;

export const DocumentLinkSchema = z.object({
  type: z.enum(['team', 'project']),
  id: z.string(),
});

export type DocumentLink = z.infer<typeof DocumentLinkSchema>;

export const DocumentProcessingEventTypeSchema = z.enum([
  'ocr_finished',
  'post_processing_finished',
  'title_extraction_finished',
  'classification_finished',
  'embeddings_finished',
  'statement_extraction_finished',
  'table_of_contents_finished',
]);

export type DocumentProcessingEventType = z.infer<
  typeof DocumentProcessingEventTypeSchema
>;

export const DocumentProcessingEventSchema = z.object({
  id: z.string(),
  documentId: z.string(),
  eventType: DocumentProcessingEventTypeSchema,
  eventData: z.object({}),
  createdAt: z.coerce.date(),
});

export type DocumentProcessingEvent = z.infer<
  typeof DocumentProcessingEventSchema
>;

export const DocumentDetailSchema = DocumentSummarySchema.extend({
  links: z.array(DocumentLinkSchema),
  viewUrl: z.string(),
  downloadUrl: z.string(),
  uploadUrl: z.string(),
});

export type DocumentDetail = z.infer<typeof DocumentDetailSchema>;

export const ListDocumentsQuerySchema = z
  .object({
    documentIds: commaDelimitedList(z.string().nanoid().array()).optional(),
    excludeDocumentIds: commaDelimitedList(
      z.string().nanoid().array(),
    ).optional(),
    isArchived: z.boolean().optional(),
    isProcessed: z.boolean().optional(),
    projectIds: commaDelimitedList(z.string().nanoid().array()).optional(),
    teamIds: commaDelimitedList(z.string().nanoid().array()).optional(),
    title: z.string().optional(),
  })
  .merge(createPaginationRequestQuerySchema(z.enum(['createdAt'])));

export type ListDocumentsQuery = z.infer<typeof ListDocumentsQuerySchema>;

export const ListDocumentsResponseSchema = z.array(DocumentSummarySchema);

export type ListDocumentsResponse = z.infer<typeof ListDocumentsResponseSchema>;

export const CreateDocumentRequestSchema = z.object({
  title: z.string(),
  description: z.string().nullable(),
  fileType: z.string(),
  fileName: z.string(),
  links: z.array(DocumentLinkSchema).min(1),
});

export type CreateDocumentRequest = z.infer<typeof CreateDocumentRequestSchema>;

export const GetDocumentPathSchema = z.object({
  documentId: z.string(),
});

export type GetDocumentPath = z.infer<typeof GetDocumentPathSchema>;

export const UpdateDocumentBodySchema = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
  archived: z.boolean().optional(),
  links: z.array(DocumentLinkSchema).min(1).optional(),
});

export type UpdateDocumentBody = z.infer<typeof UpdateDocumentBodySchema>;

export const ListDocumentProcessingEventsQuerySchema = z.object({
  documentIds: commaDelimitedList().optional(),
});

export type ListDocumentProcessingEventsQuery = z.infer<
  typeof ListDocumentProcessingEventsQuerySchema
>;

export const ListDocumentProcessingEventsResponseSchema = z.array(
  DocumentProcessingEventSchema,
);

export type ListDocumentProcessingEventsResponse = z.infer<
  typeof ListDocumentProcessingEventsResponseSchema
>;

export const UpdateManyDocumentsBodySchema = z.object({
  documentIds: z.array(z.string()).min(1),
  shouldProcess: z.boolean(),
});

export type UpdateManyDocumentsBody = z.infer<
  typeof UpdateManyDocumentsBodySchema
>;

export const CreateManyDocumentsResponseSchema = z.object({
  documentIds: z.array(z.string()).min(1),
});
