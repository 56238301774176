export const passwordRegex = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/;

export const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;

export const urlRegex =
  /^(https?:\/\/|http?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

export const localstackS3UrlRegex =
  /http:\/\/[a-z0-9-]+\.s3\.localhost\.localstack\.cloud:4566\/.+/;

// Message references will be as a JSON array of strings wrapped with round brackets
export const messageReferenceRegex = /\(([^)]+)\)/g;
