import Spin from 'antd/es/spin';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import AuthenticatedLayout from '@components/layouts/AuthenticatedLayout';
import UnauthenticatedLayout from '@components/layouts/UnauthenticatedLayout';
import { useAuthWhoami } from '@queries/auth';
import { useSessionUserId } from '@utils/auth';
import { usePrevious } from '@utils/hooks';
import { track } from '@utils/mixpanel';

const Root = () => {
  const authWhoamiQuery = useAuthWhoami();
  const authUserId = authWhoamiQuery.data?.userId;
  const { pathname: urlPath } = useLocation();
  const previousUrlPath = usePrevious(urlPath);
  const sessionUserId = useSessionUserId();

  // Fire analytic events when the location changes, specifically sending the path minus ids and sensitive query params
  useEffect(() => {
    if (urlPath === previousUrlPath) return;
    const path = location.pathname;
    track('PAGE_VIEWED', {
      urlPath: path,
      sessionUserId,
      metadata: {
        previousUrlPath,
      },
    });
  }, [previousUrlPath, sessionUserId, urlPath]);

  if (authWhoamiQuery.isLoading) {
    return (
      <div
        style={{
          height: '100dvh',
          width: '100dvw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin />
      </div>
    );
  }

  if (!authUserId) {
    return (
      <UnauthenticatedLayout>
        <Outlet />
      </UnauthenticatedLayout>
    );
  }

  return (
    <AuthenticatedLayout>
      <Outlet />
    </AuthenticatedLayout>
  );
};

export default Root;
