import Card from 'antd/es/card';
import Typography from 'antd/es/typography';

const TermsAndConditionsPage = () => {
  return (
    <Card>
      <Typography.Title level={1}>Terms and Conditions</Typography.Title>
      <Typography.Paragraph>
        Moderately AI Inc. Terms and Conditions. Introduction 1.1 Purpose
        Statement: Welcome to Moderately AI Inc.! Our Terms and Conditions
        establish the rules for using our services, promoting fairness and
        transparency in our digital community. 1.2 Acceptance Clause: By using
        our platform, you agree to abide by the terms outlined in these Terms
        and Conditions. Your continued use implies your understanding and
        acceptance of these conditions. User Rights and Responsibilities 2.1
        User Conduct: Users are expected to engage in respectful and lawful
        behavior on our platform. Violations may lead to consequences outlined
        in Section 7, ensuring a positive and secure online environment. 2.2
        Account Security: Protecting your account is crucial. Users are
        responsible for maintaining the security of their accounts and
        passwords, contributing to the overall safety of our digital community.
        Service Description 3.1 Overview of Services: At Moderately AI Inc., we
        provide software for legal industry professionals. Stay informed about
        any changes through our Modifications Clause to have a seamless user
        experience. 3.2 Modifications Clause: We reserve the right to modify or
        discontinue services for improvement. Users will be notified of
        significant changes, maintaining transparency and user awareness.
        Payment Terms 4.1 Fee Structure: Users agree to pay the specified fees
        for our services. Payment methods accepted include Credit Cards,
        ensuring a straightforward financial transaction process. 4.2 Billing
        Policies: Billing occurs monthly based on the subscription selected.
        Familiarize yourself with our billing policies to avoid disruptions and
        manage your financial commitment. Privacy Policy Integration 5.1 Data
        Handling: We handle user data as outlined in our Privacy Policy,
        respecting user privacy and complying with data protection regulations.
        5.2 Cookies Usage: Cookies are used for analytics and security purposes.
        Manage your cookie settings through your account preferences, giving
        users control over their online experience. Intellectual Property Rights
        6.1 Ownership Statement: Moderately AI Inc. retains ownership of all
        intellectual property, ensuring a secure and innovative environment for
        our community. 6.2 User Content Rights: Users grant Moderately AI Inc.
        rights to use content they generate on our platform, striking a balance
        between user-generated content and platform interests. Termination and
        Suspension 7.1 Grounds for Termination: We may terminate accounts for
        violations of our Terms and Conditions and for service abuse. Adhering
        to our community guidelines ensures a positive online environment for
        everyone. 7.2 Consequences of Termination: Termination may result in
        loss of access to our services. Compliance ensures uninterrupted access
        to our services and fosters a healthy online community. Dispute
        Resolution 8.1 Resolution Methods: Disputes will be resolved through
        arbitration, ensuring a fair and efficient conflict resolution
        mechanism. 8.2 Jurisdiction Clause: The governing law for disputes is
        the law of Canada, providing legal clarity and setting setting
        expectations for users. By using our services, you acknowledge that you
        have read and agreed to these Terms and Conditions. August 31st 2024.
      </Typography.Paragraph>
    </Card>
  );
};

export default TermsAndConditionsPage;
